export type ErrorDescription = {
  errors: { [key: string]: { message: string, details: string }[] }
}

export class Result<T> {
  isOk: boolean = true;
  error: ValidationError | ErrorDescription | Error | undefined
  data: T

  constructor(data: T, isOk?: boolean, error?: Error | ValidationError | ErrorDescription) {
    this.data = data
    this.isOk = isOk !== undefined ? isOk : true
    this.error = error
  }

  mainMessage(): string {
    if ("errors" in this.error) {
      return Object.values(this.error.errors)
        .flatMap(errors => errors.map(({ details }) => details))
        .join(", ")
    }

    return this.error.message
  }

}

export class SuccessResult<T> extends Result<T> {
  constructor(data: T) {
    super(data, true)
  }
}

export class ErrorResult extends Result<null> {
  constructor(error: Error | ValidationError) {
    super(null, false, error)
  }
}

export class ValidationError extends Error {
  constructor(public errors: { [key: string]: { message: string, details: string }[] }) {
    super("Validation error")
  }
}

export default Result
