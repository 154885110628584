/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SelectOptions } from './SelectOptions';
import {
    instanceOfSelectOptions,
    SelectOptionsFromJSON,
    SelectOptionsFromJSONTyped,
    SelectOptionsToJSON,
} from './SelectOptions';

/**
 * @type VariableConfig
 * 
 * @export
 */
export type VariableConfig = SelectOptions;

export function VariableConfigFromJSON(json: any): VariableConfig {
    return VariableConfigFromJSONTyped(json, false);
}

export function VariableConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableConfig {
    if (json == null) {
        return json;
    }
    if (instanceOfSelectOptions(json)) {
        return SelectOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function VariableConfigToJSON(value?: VariableConfig | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfSelectOptions(value)) {
        return SelectOptionsToJSON(value as SelectOptions);
    }

    return {};
}

