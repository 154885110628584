
import Form, { Field, HelperMessage } from "components/ui/form"
import WarningBanner from "components/Banner"
import Help from "components/Help"
import { DialogLayout } from "components/Layout"
import Button, { ButtonGroup } from "components/ui/Button"
import Textfield from "components/ui/Textfield"
import { AsyncSelectWithIcon } from "components/Select"

import useIssueSearch from "./useIssueSearch"
import useCreateTemplate from "./useCreateTemplate"

export type Props = {
  onClose?: () => void
  onCompleted?: (params: { id: string; name: string }) => void
}


const validateIssueId = (value, _formState, { dirty }) => {
  if (!dirty) {
    return
  }

  if (!value) {
    return "Please select an issue to create the template from"
  }

  return
}

const validateName = (value, _formState, { dirty }) => {
  if (!dirty) {
    return
  }

  if (!value.trim()) {
    return "Cannot be blank"
  }

  return
}

const testIdFor = (selector: string) => `create-template-form__${selector}`

const AddDialog = ({
  onClose,
  onCompleted,
}: Props) => {
  const searchIssues = useIssueSearch()

  const { createTemplate } = useCreateTemplate()

  const handleSubmit = async (values) => {
    try {
      const result = await createTemplate({
        issueId: values.issue.key,
        name: values.name,
        subtasksEnabled: true
      })

      onCompleted?.({ id: result.id, name: result.name })
    } catch (error) {
      return {
        general: error.message,
      }
    }
  }

  const handleReset = () => {
    onClose()
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({
        formProps, submitting, getState, setFieldValue, dirty
      }) => {
        const { values, submitErrors } = getState()

        return (
          <form {...formProps}>
            <DialogLayout
              header={<h3>Create Template from Existing Issue</h3>}
              toolbar={<Help url="https://go.appliger.com/import-create" />}
              actions={
                <ButtonGroup>
                  <Button
                    onClick={handleReset}
                    isDisabled={submitting}
                    appearance="subtle"
                    testId={testIdFor("cancel-button")}
                  >
                    Cancel
                  </Button>
                  <Button
                    appearance="primary"
                    isDisabled={!dirty || submitting}
                    type="submit"
                    testId={testIdFor("create-button")}
                    isLoading={submitting}
                  >
                    Create
                  </Button>
                </ButtonGroup>
              }
            >
              {submitErrors?.general && !submitting && <WarningBanner message={submitErrors.general} />}
              <Field
                name="issue"
                isDisabled={submitting}
                testId={testIdFor("issue-field")}
                label="Source Issue"
                isRequired
                validate={validateIssueId}
              >
                {({ fieldProps }) => (
                  <AsyncSelectWithIcon
                    {...fieldProps}
                    autoFocus={true}
                    placeholder="Choose an existing issue to import as a template"
                    onChange={(value: { key: string; name: string }) => {
                      setFieldValue("issue", value)
                      setFieldValue("name", `${value.name} template`)
                    }}
                    loadOptions={searchIssues}
                    noOptionsMessage={() => "Not found"}
                    loadingMessage={() => "Searching..."}
                  />
                )}
              </Field>
              <Field
                name="name"
                label="Name"
                isRequired={true}
                isDisabled={submitting || !values.issue}
                testId={testIdFor("name-field")}
                validate={validateName}
              >
                {({ fieldProps, error, valid }) =>
                  <>
                    <Textfield {...fieldProps} />
                    {!valid && error && <HelperMessage>{error}</HelperMessage>}
                  </>
                }
              </Field>
            </DialogLayout>
          </form>
        )
      }}
    </Form>
  )
}

export default AddDialog
