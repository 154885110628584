import React, { useState, useImperativeHandle, forwardRef } from 'react'
import Form, { Field, HelperMessage } from 'components/ui/form'
import Textfield from 'components/ui/Textfield'

import InlineMessage from "components/ui/InlineMessage"
import Button from "components/ui/Button"

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/ui/ModalDialog"

import useTemplateCopying from './useTemplateCopying'

type Values = {
  id: string
  name: string
}

type Props = {
  onCompleted: (values: Values) => void
}

type RequestError = {
  message: string | null
  details?: string | null
}

type State = {
  requestError: RequestError
  isOpen: boolean
} & Values

export type Ref = {
  open: (values: Values) => void
}

const DEFAULT_STATE = {
  requestError: { message: null, details: null },
  isOpen: false,
  id: '',
  name: '',
}

const CopyDialog: React.ForwardRefRenderFunction<Ref, Props> = (
  { onCompleted },
  ref
) => {
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const { copyTemplate } = useTemplateCopying()

  const { isOpen, requestError, id, name } = state

  const initialValues = { name: `Copy of ${name}`, id }

  const setRequestError = (requestError: RequestError) =>
    setState({ ...state, requestError })

  const handleClose = () => {
    setState(DEFAULT_STATE)
  }

  const handleSubmit = async ({ id, name }) => {
    setRequestError({ message: null, details: null })

    try {
      const result = await copyTemplate({ id, name })
      handleClose()
      onCompleted(result)
    } catch (error) {
      setRequestError(error)
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      open: (values: Values) => {
        setState({ ...state, ...values, isOpen: true })
      },
    }),
    [setState, state]
  )

  if (!isOpen) return null

  const errorMessage = requestError.message
  const errorDetails = requestError.details

  const validateTemplateName = (name: string) => {
    if (!name.trim()) {
      return "Please provide the name"
    }

    return
  }

  return (
    <Modal onClose={handleClose} testId="copy-dialog" shouldScrollInViewport={false}>
      <Form onSubmit={handleSubmit}>
        {({
          formProps,
          submitting,
        }) => {
          return (
            <form id="template-copy" {...formProps}>
              <ModalHeader>
                <ModalTitle>Copy Template</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Field name="id" defaultValue={initialValues.id} isDisabled={true}>
                  {({ fieldProps }) => <input type="hidden" {...fieldProps} />}
                </Field>

                <Field
                  label="Name"
                  isRequired
                  validate={validateTemplateName}
                  name="name"
                  defaultValue={initialValues.name}
                  isDisabled={submitting}
                  testId="template-copy-form__name-field"
                >
                  {({ fieldProps, valid, error }) =>
                    <>
                      <Textfield {...fieldProps} autoFocus />
                      {!valid && error && <HelperMessage>{error}</HelperMessage>}
                    </>
                  }
                </Field>
                {errorMessage && (
                  <div style={{ marginTop: 16 }}>
                    <InlineMessage title={errorMessage} appearance="error">
                      <p>{errorDetails}</p>
                    </InlineMessage>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button appearance="subtle" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  type="submit"
                  autoFocus
                  isDisabled={submitting}
                  isLoading={submitting}
                >
                  Copy
                </Button>
              </ModalFooter>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}

export default forwardRef<Ref, Props>(CopyDialog)
