import { useAppContext } from "components"
import Empty from "components/Templates/Empty"
import Maintenance from "components/maintenance"
import Form from "components/ui/form"

import Actions from "./Actions"
import Layout from "./Layout"
import NewIssueForm from "./Form"

import useHandlers from "./useHandlers"

type Props = {
  onClose?: () => void
}

const Main = (props: Props): JSX.Element => {
  const { onClose } = props

  const { maintenanceModeOn } = useAppContext()

  if (maintenanceModeOn) {
    return <Maintenance onDismiss={onClose} />
  }

  return (
    <Form onSubmit={() => { }}>
      {({ formProps, getState: getFormState }) => {
        const {
          actions,
          creationResults,
          currentStep,
          errorMessage,
          fullSelectedTemplate,
          handleProjectChange,
          handleTemplateChange,
          handleIssuesChange,
          isLoading,
          isPrefilling,
          isSubmitting,
          selectedIssueIds,
          noTemplates,
          missingFields,
          projects,
          selectedIssueType,
          selectedProject,
          selectedTemplate,
          steps,
          templates,
          variables,
          warnings,
        } = useHandlers({ onClose, getFormState })
        return (
          <form {...formProps}>
            <Layout
              actions={<Actions items={actions} isProcessing={isSubmitting} onClose={onClose} />}
              isLoading={isLoading && templates.length == 0}
            >
              {noTemplates ? (
                <Empty />
              ) : (
                <NewIssueForm
                  creationResults={creationResults}
                  currentStep={currentStep}
                  errorMessage={errorMessage}
                  fullSelectedTemplate={fullSelectedTemplate}
                  handleProjectChange={handleProjectChange}
                  handleTemplateChange={handleTemplateChange}
                  handleIssuesChange={handleIssuesChange}
                  selectedIssueIds={selectedIssueIds}
                  isPrefilling={isPrefilling}
                  isSubmitting={isSubmitting}
                  isDisabled={isLoading}
                  missingFields={missingFields}
                  projects={projects}
                  selectedIssueType={selectedIssueType}
                  selectedProject={selectedProject}
                  selectedTemplate={selectedTemplate}
                  steps={steps}
                  templates={templates}
                  variables={variables}
                  warnings={warnings}
                />
              )}
            </Layout>
          </form>
        )
      }}
    </Form>
  )
}

export default Main
