import { useState } from "react"
import { Label } from "components/ui/FieldBase"
import Popup from "components/ui/Popup"
import { IconButton } from "components/ui/Button"
import InfoIcon from "components/ui/icons/QuestionCircleIcon"
import { token } from "components/ui/tokens"

import useStyles from "./useStyles"

type Props = {
  label: string
  name: string
  isRequired?: boolean
  hint?: React.ReactNode
  children: React.ReactNode
}

const Labeled = ({
  label,
  name,
  isRequired,
  hint,
  children,
}: Props): JSX.Element => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {/* @ts-ignore */}
        <Label
          isFirstChild={false}
          htmlFor={name}
          label={label || name}
          isRequired={isRequired}
        />
        {hint && (
          <Popup
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            placement="auto"
            zIndex={999}
            content={() => <div style={{ padding: 18 }}>{hint}</div>}
            trigger={(triggerProps) => (
              <div className={classes.hint}>
                <IconButton
                  {...triggerProps}
                  appearance="subtle"
                  spacing="compact"
                  shape="circle"
                  onClick={() => setIsOpen(!isOpen)}
                  label={label || name}
                  icon={(iconProps) =>
                    <InfoIcon
                      size="small"
                      label={`${label || name} info`}
                      primaryColor={token('color.icon.subtle')}
                      {...iconProps}
                    />
                  }
                />
              </div>
            )}
          />
        )}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default Labeled
