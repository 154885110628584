/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateApplyRequestVariablesValue
 */
export interface TemplateApplyRequestVariablesValue {
}

/**
 * Check if a given object implements the TemplateApplyRequestVariablesValue interface.
 */
export function instanceOfTemplateApplyRequestVariablesValue(value: object): value is TemplateApplyRequestVariablesValue {
    return true;
}

export function TemplateApplyRequestVariablesValueFromJSON(json: any): TemplateApplyRequestVariablesValue {
    return TemplateApplyRequestVariablesValueFromJSONTyped(json, false);
}

export function TemplateApplyRequestVariablesValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateApplyRequestVariablesValue {
    return json;
}

export function TemplateApplyRequestVariablesValueToJSON(value?: TemplateApplyRequestVariablesValue | null): any {
    return value;
}

