import { Template, TemplateVariable } from '@easy-templates/types'
import { FeatureFlags } from 'components/AppContextProvider'

import BaseAction from './base'
import { State, StepStatus, StepPage } from '../types'

const isOptional = ({ required }: TemplateVariable) => !required

export default class FullTemplateLoadedAction extends BaseAction {
  template: Template
  featureFlags: FeatureFlags

  constructor(template: Template, { featureFlags }) {
    super()
    this.template = template
    this.featureFlags = featureFlags
  }

  apply(state: State): State {
    const rootIssueChildrenIds = this.template.tree[this.template.tree.root[0]] || []
    const hierarchical = this.featureFlags.selectIssuesToCreate
      && rootIssueChildrenIds.length > 0


    const issueIds = this.template.createChildren ?
      Object.keys(this.template.issues) : [this.template.rootIssueId]

    const variables = this.template.variables.filter(variable => variable.issueIds.some(issueId => issueIds.includes(issueId)))

    const needsVariables = variables.length > 0

    const finalStepActions = () => {
      const actions = []

      if (this.featureFlags.prefill) {
        actions.push({
          id: "prefill",
        })
      }

      actions.push({
        id: "create",
      })

      return actions
    }

    const getSelectionStepActions = () => {
      if (hierarchical) {
        return [
          {
            id: "next",
            nextStep: StepPage.REVIEW,
          },
        ]
      }

      if (needsVariables) {
        return [
          {
            id: "next",
            nextStep: StepPage.FILL,
          },
        ]
      }

      return finalStepActions()
    }

    const getCreationStepAction = () => {
      if (needsVariables) {
        return [
          {
            id: "back",
            backStep: StepPage.FILL,
          },
          { id: "restart" }
        ]
      }

      if (hierarchical) {
        return [
          {
            id: "back",
            backStep: StepPage.REVIEW,
          },
          { id: "restart" }
        ]
      }

      return [{ id: "back", backStep: StepPage.SELECT }, { id: "restart" }]
    }


    const generateSteps = () => {
      const steps = [
        {
          id: StepPage.SELECT,
          label: "Select",
          percentageComplete: 0,
          status: StepStatus.CURRENT,
          actions: getSelectionStepActions(),
        },
      ]

      if (hierarchical) {
        const actions = needsVariables ?
          [{ id: "back", backStep: StepPage.SELECT }, { id: "next", nextStep: StepPage.FILL }] :
          [{ id: "back", backStep: StepPage.SELECT }, ...finalStepActions()]

        steps.push({
          id: StepPage.REVIEW,
          label: "Review",
          percentageComplete: 0,
          status: StepStatus.UNVISITED,
          actions,
        })
      }

      if (needsVariables) {
        const actions = hierarchical ?
          [{ id: "back", backStep: StepPage.REVIEW }, ...finalStepActions()] :
          [{ id: "back", backStep: StepPage.SELECT }, ...finalStepActions()]

        steps.push({
          id: StepPage.FILL,
          label: "Fill",
          percentageComplete: 0,
          status: StepStatus.UNVISITED,
          actions,
        })
      }

      steps.push({
        id: StepPage.RESULT,
        label: "Result",
        percentageComplete: 0,
        status: StepStatus.UNVISITED,
        actions: getCreationStepAction(),
      })

      return steps
    }

    const steps = generateSteps()
    const currentStep = steps.find((step) => step.id === state.currentStep)

    return {
      ...state,
      actions: currentStep.actions,
      fullSelectedTemplate: this.template,
      isValid: this.template.variables.every(isOptional),
      issueIds,
      variables,
      steps
    }
  }
}
