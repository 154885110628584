import Form, { Field, HelperMessage, useFormState } from 'components/ui/form'

import Button from "components/ui/Button"

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/ui/ModalDialog"

import Textfield from "components/ui/Textfield"

type Props = {
  onDismiss: () => void
  initialValues: {
    templateId: string
    id: string
    summary: string
  }
  onSubmit: (params: {
    id: string
    summary: string
    templateId: string
  }) => Promise<unknown>
}

const IssueCopyForm = ({
  initialValues,
  onDismiss,
  onSubmit,
}: Props): JSX.Element => {
  const handleCopyDialogCloseClick = () => {
    onDismiss()
  }

  const handleFormSubmit = (values) => {
    return onSubmit({ ...initialValues, ...values })
  }

  const validateSummary = (value: string) => {
    if (!value) {
      return "Cannot be blank"
    }

    return
  }

  return (
    <Modal onClose={handleCopyDialogCloseClick} shouldScrollInViewport={false} testId="copy-issue-dialog">
      <Form onSubmit={handleFormSubmit}>
        {({ formProps, submitting }) => {
          return (
            <form id="issue-copy" name="template-issue-copy" {...formProps}>
              <ModalHeader>
                <ModalTitle>Copy Issue</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Field
                  name="summary"
                  defaultValue={initialValues.summary}
                  label="Summary"
                  isRequired={true}
                  validate={validateSummary}
                >
                  {({ fieldProps, valid, error }) =>
                    <>
                      <Textfield {...fieldProps} autoFocus />
                      {!valid && error && <HelperMessage>{error}</HelperMessage>}
                    </>
                  }

                </Field>
              </ModalBody>
              <ModalFooter>
                <Button
                  appearance="subtle"
                  onClick={handleCopyDialogCloseClick}
                  testId="copy-issue-form_cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  type="submit"
                  form="issue-copy"
                  isDisabled={submitting}
                  isLoading={submitting}
                  testId="copy-issue-form_copy-button"
                >
                  Copy
                </Button>
              </ModalFooter>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}

export default IssueCopyForm
