import React from "react";
import InlineMessage from "components/ui/InlineMessage";
import Debug from "components/Debug";

type Props = {
  type: string;
  label?: string;
  value?: unknown;
  schema?: unknown;
};

const UnsupportedField = ({ value, type }: Props): JSX.Element => {
  return (
    <div
      style={{
        height: 36,
        lineHeight: "36px",
        paddingLeft: 6,
      }}
    >
      <InlineMessage title="Read-only field" type="warning">
        Currently, it's not possible to edit fields of the type &quot;{type}&quot;
        <p style={{ color: "grey" }}>Field value:</p>
        {value === undefined ?
          <Debug value={value} /> :
          "(No value)"}
      </InlineMessage>
    </div>
  );
};

export default UnsupportedField;
