import { Jira } from "./jira"

export enum TemplateScope {
  GLOBAL = 0,
  PROJECTS = 1
}

export enum VariableType {
  text = 'text',
  longtext = 'longtext',
  number = 'number',
  select = 'select',
  multiselect = 'multiselect',
  date = 'date',
  user = 'user'
}


// Template

export type TemplateVariable = {
  id: string
  description: string
  type: VariableType
  required: boolean
  label: string
  default?: string | number | undefined
  issueIds: string[]
  config?: {
    options?: string[]
  }
  // Deprecated
  rank?: string
  // Deprecated
  fieldName?: string
  // Deprecated
  issueId?: string
}

export type FieldBody =
  | string
  | number
  | { [key: string]: unknown }
  | undefined
  | null

export type IssueField = {
  id: string
  name: string
  isDisabled: boolean
  isVirtual?: boolean
  body: FieldBody
  meta: Jira.FieldMetadata
  // variables: TemplateVariable[];
}

export type Fields = {
  [name: string]: any
  summary: string
  description: string
  issuetype: {
    id: string
    name: string
    hierarchyLevel: number
    subtask: boolean
    iconUrl: string
  }
  project: {
    id: string
    key: string
  }
}

export type TemplateIssueData = {
  id: string
  jiraKey: string | undefined
  parentId: string | undefined
  fields: Fields
  disabledFields: string[]
}

export type OriginalTemplateIssueData = {
  id: string
  parentId: string | undefined
  fields: Fields
  disabledFields: string[]
}

export type TemplateIssue = TemplateIssueData

export type TemplateIssuesIndexItem = {
  id: string
  jiraKey?: string
  parentId: string | undefined
  iconUrl: string
  name: string
  rank: string
  level: number
}

export type Template = {
  version?: number
  iconUrl?: string
  id: string
  name: string
  createChildren: boolean
  rootIssueId: string
  issuetype: {
    id: string
    iconUrl: string
    name: string
  }
  issues: {
    [id: string]: TemplateIssuesIndexItem
  }
  tree: {
    [id: string]: string[]
    root: string[]
  }
  variables: TemplateVariable[]
  scope: TemplateScope
  scopeValue: string[],
  createdBy: string | null
}

export type OriginalTemplate = {
  id: string
  name: string
  createChildren: boolean
  rootIssueId: string
  issuetype: {
    id: string
    iconUrl: string
    name: string
  }
  issues: OriginalTemplateIssueData[]
  variables: Template["variables"]
  insertedAt: string
  updatedAt: string
}

// Legacy DC Templates
export type LegacyDCTemplate = {
  id: string
  name: string
  subtasksDisabled: boolean
  fields: Fields
  subtasks: Fields[]
}

// Template form
export type VariableValue = string
export type VariableValues = { [id: string]: VariableValue }

// CRUD (and more) methods for Templates, Issues, Fields and App data
export namespace TemplatesRepo {
  export interface Interface extends TemplateInterface, IssueInterface, VariableInterface {
    getMigrationVersion(): Promise<number>
    setMigrationVersion(version: number): Promise<number>
    clearAll(cursor?: string): Promise<void>
  }

  interface TemplateInterface {
    put(template: Template, issues: TemplateIssue[]): Promise<void>
    setTemplate(template: Template): Promise<void>
    get(id: string): Promise<Template>
    update(
      id: string,
      attrs: { name: string; createChildren: boolean }
    ): Promise<Template>
    delete(id: string): Promise<void>
    getIndex(): Promise<Template[]>
  }

  interface IssueInterface {
    getIssue(templateId: string, id: string): Promise<TemplateIssue>
    deleteIssue(templateId: string, id: string): Promise<void>
    getIssues(templateId: string): Promise<TemplateIssue[]>
    setTemplateIssue(templateId: string, issue: TemplateIssue): Promise<void>
    setTemplateIssues(
      templateId: string,
      issues: TemplateIssue[]
    ): Promise<void>

    toggleIssueField(
      templateId: string,
      issueId: string,
      id: string,
      isEnabled: boolean
    ): Promise<void>

    updateIssueField(
      templateId: string,
      issueId: string,
      id: string,
      value: unknown
    ): Promise<void>
  }

  interface VariableInterface {
    createVariable(templateId: string, variable: TemplateVariable): Promise<TemplateVariable>
    updateVariable(
      templateId: string,
      id: string,
      attributes: {
        label?: string,
        description?: string,
        required?: boolean,
        default?: unknown
      }
    ): Promise<TemplateVariable>
    deleteVariable(templateId: string, id: string): Promise<void>
  }
}
