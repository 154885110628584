/*
  This is a fallback implementation for the RichEditor component.
  It's used in the Data Center due to the bug
  when pressing the "c" key opens the Jira Issue Creation dialog (DEV-1221)
*/

import Textfield, { TextFieldProps } from "components/ui/Textfield"
import TextArea, { TextAreaProps } from 'components/ui/Textarea'
import { ButtonProps, IconButton } from "components/ui/Button"
import InlineMessage from "components/ui/InlineMessage"
import Tooltip from "components/ui/Tooltip"

import {
  Icon as AddonIcon,
} from './extension'

type Props = TextFieldProps & {
  autoFocus?: boolean,
  allowInsertVariable?: boolean,
  onVariableRequest?: (onAddVariable: (label: string) => void) => void
  errorMessage?: string,
  inputRef?: React.RefObject<any>
}

type TextAreaFieldProps = TextAreaProps & {
  autoFocus?: boolean,
  allowInsertVariable?: boolean,
  onVariableRequest?: (onAddVariable: (label: string) => void) => void
  errorMessage?: string,
  inputRef?: React.RefObject<HTMLTextAreaElement>
}

const ErrorMessage = ({ message }: { message: string }) => {
  if (!message) {
    return null
  }

  return (
    <InlineMessage appearance='error' secondaryText={message}>
      {message}
    </InlineMessage>
  )
}

const AddVariableButton = (buttonProps: Pick<ButtonProps, "onClick">) =>
  <Tooltip content="Insert Variable">
    {(tooltipProps) => (
      <IconButton
        {...tooltipProps}
        appearance="subtle"
        icon={(iconProps) => <AddonIcon {...iconProps} size="medium" />}
        testId='toolbar-add-variable-button'
        label="Insert Variable"
        {...buttonProps}
      />
    )}
  </Tooltip>

export const TextEditor = ({
  allowInsertVariable,
  errorMessage,
  isDisabled,
  onVariableRequest,
  onChange,
  inputRef,
  ...fieldProps
}: Props) => {
  const addToInput = (label: string) => {
    if (!inputRef.current) {
      return
    }

    const textarea = inputRef.current

    let start_position = textarea.selectionStart
    let end_position = textarea.selectionEnd

    const newValue = `${textarea.value.substring(
      0,
      start_position
    )}<<${label}>>${textarea.value.substring(
      end_position,
      textarea.value.length
    )}`

    textarea.value = newValue
    // @ts-ignore: It expect an Event, but it work with a value
    onChange?.(newValue)
  }

  const variablesProps = allowInsertVariable ? {
    elemAfterInput: (<AddVariableButton onClick={() => onVariableRequest(addToInput)} />)
  } : {}


  return (
    <>
      <Textfield
        {...fieldProps}
        ref={inputRef}
        onChange={onChange}
        autoFocus={true}
        isDisabled={isDisabled}
        {...variablesProps}
      />
      <ErrorMessage message={errorMessage} />
    </>
  )
}

export const TextAreaEditor = ({
  allowInsertVariable,
  errorMessage,
  isDisabled,
  onVariableRequest,
  onChange,
  inputRef,
  ...fieldProps
}: TextAreaFieldProps) => {
  const addToInput = (label: string) => {
    if (!inputRef.current) {
      return
    }

    const textarea = inputRef.current

    let start_position = textarea.selectionStart
    let end_position = textarea.selectionEnd

    const newValue = `${textarea.value.substring(
      0,
      start_position
    )}<<${label}>>${textarea.value.substring(
      end_position,
      textarea.value.length
    )}`

    textarea.value = newValue
    // @ts-ignore: It expect an Event, but it work with a value
    onChange?.(newValue)
  }

  return (
    <div style={{ position: 'relative' }}>
      {/* @ts-ignore */}
      <TextArea
        {...fieldProps}
        ref={inputRef}
        onChange={onChange}
        autoFocus={true}
        isDisabled={isDisabled} />
      {allowInsertVariable && (
        <div style={{ position: 'absolute', right: 0, top: 0 }}>
          <AddVariableButton onClick={() => onVariableRequest(addToInput)} />
        </div>
      )}
      <ErrorMessage message={errorMessage} />
    </div >
  )
}
