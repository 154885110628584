import { useReducer, useRef, useEffect } from "react"
import sortBy from "lodash/sortBy"

import { StringField } from "@easy-templates/lib"
import { Job, SubJob, SubJobStatus, Jira, Template, TemplateVariable, JobStatus, VariableValues } from "@easy-templates/types"

import BackIcon from "components/ui/icons/BackIcon"
import PrefillIcon from "components/ui/icons/PrefillIcon"
import NextIcon from "components/ui/icons/NextIcon"
import RestartIcon from "components/ui/icons/RestartIcon"

import { ButtonProps } from "components/ui/Button"

import { useAnalytics, UIEvent, UIScope } from "components/Analytics"

import { normalizeVariableValues } from "components/Variables"
import { useAppContext } from "components/AppContextProvider"

import useTemplate from "hooks/useTemplate"
import useJob from "hooks/useJob"

import { State, StepPage, StepStatus } from "./types"
import useTemplates from "./useTemplates"
import useCreateIssue from "./useCreateIssue"
import usePrefillIssue from "./usePrefillIssue"

import {
  ActionType,
  ContextChangedAction,
  FullTemplateLoadedAction,
  JobUpdatedAction,
  IssueCreatedAction,
  IssueCreationRequestedAction,
  IssuePrefillRequestedAction,
  IssuePrefillCompletedAction,
  IssuetypeSelectedAction,
  IssuesSelectedAction,
  ProjectSelectedAction,
  RandomlyFailedAction,
  TemplateSelectedAction,
  TemplatesLoadedAction,
  LoadingStatusChangedAction,
  StepChangedAction,
} from "./actions"

type ComponentAction = {
  id: string
  label: string
  onClick: () => void
  isDisabled?: boolean
  isProcessing?: boolean
  appearance?: ButtonProps['appearance']
  leftIcon?: typeof NextIcon
  rightIcon?: typeof NextIcon
}

export enum CreationStatus {
  IN_PROGRESS = "in-progress",
  WARNING = "warning",
  ERROR = "error",
  OK = "ok",
}

type CreationResult = {
  title: string
  message: string
  id: string
  children?: CreationResult[]
  status: CreationStatus
  onClick?: () => void
}

const defaultState = {
  actions: [],
  allProjects: [],
  allTemplates: [],
  currentStep: StepPage.SELECT,
  errorMessage: null,
  fullSelectedTemplate: null,
  hierarchical: false,
  isLoading: true,
  isPrefilling: false,
  issuetypes: [],
  issueIds: [],
  isValid: false,
  needsVariables: false,
  noTemplates: false,
  projects: [],
  results: {},
  selectedIssueType: null,
  selectedProject: null,
  selectedTemplate: null,
  steps: [],
  templates: [],
  variables: [],
  warnings: [],
}

interface Return {
  actions: ComponentAction[]
  creationResults: CreationResult[]
  handleIssueTypeChange: (issuetype: Jira.IssueTypeDetails) => void
  handleProjectChange: (project: Jira.Project) => void
  handleTemplateChange: (template: Template) => void
  handleIssuesChange: (issueIds: string[]) => void
  isLoading: boolean
  isSubmitting: boolean
  issuetypes: Jira.IssueTypeDetails[]
  noTemplates: boolean
  projects: Jira.Project[]
  templates: Template[]
  errorMessage?: string | null | undefined
  fullSelectedTemplate: Template | null | undefined
  isPrefilling: boolean
  isValid: boolean
  missingFields: string[]
  selectedIssueType: Jira.IssueTypeDetails | null | undefined
  selectedProject: Jira.Project | null | undefined
  selectedTemplate: Template | null | undefined
  selectedIssueIds: string[]
  steps: State["steps"]
  currentStep: State["currentStep"]
  variables: TemplateVariable[]
  warnings: string[]
}

type Params = {
  getFormState: () => {
    values: { [key: string]: unknown }
    valid: boolean
    invalid: boolean
    dirty: boolean
  }
  initialState?: { [key: string]: unknown }
  onClose?: () => void
}


const useHandllers = (props: Params): Return => {
  const { core, featureFlags, ...context } = useAppContext()
  const { initialState, onClose, getFormState } = props
  const { instrument } = useAnalytics(UIScope.NEW_ISSUE_FORM)

  const reducer = (state: State, action: ActionType): State =>
    action.apply(state)

  const [
    {
      actions,
      currentStep,
      errorMessage,
      fullSelectedTemplate,
      isLoading,
      isPrefilling,
      issuetypes,
      issueIds,
      isValid,
      jobId,
      noTemplates,
      projects,
      results,
      selectedIssueType,
      selectedProject,
      selectedTemplate,
      steps,
      templates,
      variables,
      warnings,
    },
    dispatch,
  ] = useReducer(reducer, { ...defaultState, ...initialState })

  const variablesFormRef = useRef<HTMLFormElement>()

  useEffect(() => {
    if (selectedProject?.id) {
      dispatch(new ContextChangedAction())
    }
  }, [selectedProject?.id])

  const resetVariablesForm = () => {
    if (variablesFormRef.current) {
      variablesFormRef.current.reset()
    }
  }

  const selectedTemplateId = selectedTemplate?.id

  const { createIssuesFromTemplate, isSubmitting: isSubmittingCreation } =
    useCreateIssue({
      onSuccess: (data: { jobId: string } | Job) => {
        if ("jobId" in data) {
          dispatch(new IssueCreatedAction(data.jobId))
        } else {
          handleJobChange(data)
        }

        resetVariablesForm()
      },
      onError: (error) => {
        dispatch(new RandomlyFailedAction(error.message))
      },
    })

  const { prefill } = usePrefillIssue({
    onComplete: (issues: Jira.IssueBean[]) => {
      dispatch(new IssuePrefillCompletedAction(issues))

      if (issues.length) {
        if (issueIds.length > 1) {
          dispatch(new StepChangedAction(StepPage.RESULT))
          const { values } = getFormState()

          createIssuesFromTemplate({
            templateId: fullSelectedTemplate.id,
            projectId: selectedProject.id,
            variableValues: normalizeVariableValues(values.variableValues),
            rootIssueKey: issues[0].key,
            issueIds
          })
        }
      } else {
        dispatch(new RandomlyFailedAction("No issues were prefilled"))
      }

      resetVariablesForm()
    },
    onError: (error) => {
      dispatch(
        new RandomlyFailedAction(
          error.message || "Can't collect the template details"
        )
      )
    },
  })

  const isSubmitting = isSubmittingCreation || !!jobId

  const handleCreateClicked = () => {
    const { invalid, values } = getFormState()

    if (!selectedProject || !selectedTemplate || invalid) return

    dispatch(new IssueCreationRequestedAction())
    dispatch(new StepChangedAction(StepPage.RESULT))

    createIssuesFromTemplate({
      templateId: selectedTemplate.id,
      projectId: selectedProject.id,
      variableValues: normalizeVariableValues(values.variableValues),
      issueIds
    })
  }

  const handleTemplateChange = async (template: Template) => {
    if (selectedTemplateId === template.id) return

    dispatch(new TemplateSelectedAction(template))
  }

  const handleIssueTypeChange = async (issueType: Jira.IssueTypeDetails) => {
    dispatch(new IssuetypeSelectedAction(issueType))
  }

  const handleIssuesChange = (issueIds: string[]) => {
    dispatch(new IssuesSelectedAction(issueIds))
  }

  const { isLoading: isLoadingTemplates } = useTemplates({
    onCompleted: async ({ templates, projects }) => {
      dispatch(
        new TemplatesLoadedAction({
          templates,
          projects,
          contextProjectId: context.project?.id,
        })
      )
    },
    onError: (error: Error) => {
      dispatch(new RandomlyFailedAction(error.message))
    },
  })

  const { isLoading: isLoadingTemplate } = useTemplate({
    id: selectedTemplateId,
    onCompleted: async (template: Template) => {
      if (fullSelectedTemplate?.id === selectedTemplateId) {
        return
      }

      dispatch(new FullTemplateLoadedAction(template, { featureFlags }))
    },
    onError: (error: Error) => {
      dispatch(
        new RandomlyFailedAction(error.message || "Error fetching template")
      )
    },
  })

  useEffect(() => {
    dispatch(new LoadingStatusChangedAction(isLoadingTemplates || isLoadingTemplate))
  }, [isLoadingTemplate, isLoadingTemplates])


  const handleJobChange = (job: Job) => {
    dispatch(new JobUpdatedAction(job))

    if (job.status === JobStatus.ERROR) {
      dispatch(new RandomlyFailedAction(job.result))
    } else if (job.status === JobStatus.SUCCESS) {
      core.showIssueCreatedMessage({ issueKey: job.result })
    }
  }

  useJob({
    id: jobId,
    onChange: handleJobChange,
    onError: (error: Error) => {
      dispatch(
        new RandomlyFailedAction(
          error.message || "Error updating creation status"
        )
      )
    },
  })

  const handleProjectChange = (project: Jira.Project) => {
    dispatch(new ProjectSelectedAction(project))
  }

  const handlePrefillClicked = async () => {
    const { invalid, values } = getFormState()

    if (invalid || !selectedProject || !selectedTemplate) return

    dispatch(new IssuePrefillRequestedAction())

    prefill({
      templateId: selectedTemplate.id,
      projectId: selectedProject.id,
      issueTypeId: selectedTemplate.issuetype.id,
      variableValues: normalizeVariableValues(values.variableValues),
      // issueTypeId: selectedIssueType.id,
    })
  }

  const handleStepClicked = (stepPage: StepPage) => {
    dispatch(new StepChangedAction(stepPage))
  }

  const buildActions = (): ComponentAction[] => {
    if (isLoading) {
      return [
        {
          id: "loading",
          appearance: "subtle",
          label: "Loading",
          isProcessing: true,
          onClick: () => { },
        },
      ]
    }

    return actions.map<ComponentAction>((action) => {
      switch (action.id) {
        case "create":
          return {
            id: "create",
            appearance: "primary",
            rightIcon: NextIcon,
            label: "Create",
            onClick: instrument(
              UIEvent.CREATE_BUTTON_CLICKED,
              handleCreateClicked),
            isProcessing: isSubmitting,
            isDisabled: isSubmitting,
          }

        case "prefill":
          return {
            id: "prefill",
            label: "Prefill",
            onClick: instrument(
              UIEvent.PREFILL_BUTTON_CLICKED,
              handlePrefillClicked),
            isProcessing: isPrefilling,
            isDisabled: isPrefilling,
            rightIcon: PrefillIcon,
          }
        case "next":
          return {
            id: "next",
            appearance: "primary",
            rightIcon: NextIcon,
            label: "Next",
            onClick: () => {
              dispatch(new StepChangedAction(action.nextStep))
            },
            isProcessing: isSubmitting,
          }
        case "restart":
          return {
            id: "restart",
            appearance: "primary",
            leftIcon: RestartIcon,
            label: "Start over",
            onClick: () => {
              handleStepClicked(StepPage.SELECT)
            },
          }
        case "back":
          return {
            id: "back",
            appearance: "default",
            label: "Back",
            onClick: () => {
              dispatch(new StepChangedAction(action.backStep))
            },
            leftIcon: BackIcon,
          }
      }
    })
  }

  const buildResultItems = (
    itemIds: string[],
    template: Template
  ) => {
    if (!itemIds?.length || !template) return []
    const { values } = getFormState()

    const getResultMessage = (result: SubJob): String => {
      return result?.status === "error" ? result?.result : ""
    }

    const items = itemIds
      .filter((id) => issueIds.includes(id))
      .map((id) => {
        const issue = template.issues[id]
        const result = errorMessage
          ? {
            issueId: id,
            status: SubJobStatus.ERROR,
            result: results[issue.id]?.result || errorMessage,
          }
          : results[issue.id]

        const summary = new StringField(
          { body: issue.name, name: "summary" },
          { variableValues: normalizeVariableValues(values.variableValues) }
        )

        return {
          id: issue.id,
          iconUrl: issue.iconUrl,
          title: summary.value(),
          message: getResultMessage(result),
          children: buildResultItems(
            template.tree[id],
            template
          ),
          rank: issue.rank,
          status: result?.status,
          onClick: () => {
            if (result.result && typeof result.result == "string") {
              core.navigateToIssue({ key: result.result })
              onClose?.()
            } else if (typeof result.result == "object") {
              const job = result.result as SubJob
              core.navigateToIssue({ key: job.result })
              onClose?.()
            }
          },
        }
      })

    return sortBy(items, "rank")
  }

  const buildSteps = () => {
    const stepsWithOnClick = steps.map((step) => ({
      ...step,
      onClick: currentStep === step.id ?
        () => { } :
        () => handleStepClicked(step.id)
    }))

    const currentStepIndex = stepsWithOnClick.findIndex((step) => step.id === currentStep)
    const currentStepObject = stepsWithOnClick[currentStepIndex]

    let prevSteps = stepsWithOnClick.slice(0, currentStepIndex)
    let nextSteps = stepsWithOnClick.slice(currentStepIndex)

    prevSteps = prevSteps.map((step) => ({
      ...step,
      percentageComplete: 100,
      status: StepStatus.VISITED,
    }))

    nextSteps = nextSteps.map((step) => ({
      ...step,
      percentageComplete: 0,
      status: StepStatus.UNVISITED,
    }))

    const stepsWithProgress = [...prevSteps, ...nextSteps]

    stepsWithProgress[currentStepIndex] = {
      ...currentStepObject,
      percentageComplete: 0,
      status: StepStatus.CURRENT,
    }

    return stepsWithProgress
  }

  return {
    actions: buildActions(),
    errorMessage,
    fullSelectedTemplate,
    creationResults: buildResultItems(
      fullSelectedTemplate?.tree.root,
      fullSelectedTemplate
    ),
    handleIssueTypeChange,
    handleProjectChange,
    handleTemplateChange,
    handleIssuesChange,
    isLoading: isLoading || (!selectedProject && projects.length > 0),
    isSubmitting,
    issuetypes,
    noTemplates,
    projects,
    selectedIssueType,
    selectedProject,
    selectedTemplate,
    selectedIssueIds: issueIds,
    steps: buildSteps(),
    currentStep,
    templates,
    isPrefilling,
    isValid,
    // FIXME: maybe replace with some message from the backend
    missingFields: [],
    variables,
    warnings,
  }
}

export default useHandllers
