import { Jira, Job, Template, TemplateVariable } from "@easy-templates/types"
// Select, Review, Fill, Result?
export enum StepPage {
  SELECT = "select",
  REVIEW = "review",
  FILL = "fill",
  RESULT = "result",
}

export enum StepStatus {
  CURRENT = "current",
  VISITED = "visited",
  UNVISITED = "unvisited",
}

type Step = {
  id: StepPage
  label: string
  percentageComplete: number
  status: StepStatus
  actions: Action[]
}

type IssueID = string

type Action = {
  id: "prefill" | "create" | "back" | "next" | "restart"
  nextStep?: StepPage
  backStep?: StepPage
}

export type State = {
  actions: Action[]
  allProjects: Jira.Project[]
  allTemplates: Template[]
  currentStep: StepPage
  errorMessage: string | null | undefined
  fullSelectedTemplate: Template | null
  isLoading: boolean
  isPrefilling: boolean
  issuetypes: Jira.IssueTypeDetails[]
  issueIds: IssueID[]
  isValid: boolean
  jobId?: string
  noTemplates: boolean
  projects: Jira.Project[]
  results: Job["results"]
  selectedIssueType: Jira.IssueTypeDetails | null | undefined
  selectedProject: Jira.Project | null | undefined
  selectedTemplate: Template | null | undefined
  steps: Step[]
  templates: Template[]
  variables: TemplateVariable[],
  warnings: string[]
}
