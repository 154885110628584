import { Template, TemplateIssue, Jira, LegacyDCTemplate } from "@easy-templates/types"
import generateId from "./generate-id"

type SortableIssue = {
  fields: {
    issuetype: {
      hierarchyLevel: number
    }
  }
}

export class TemplateUtils {
  static cleanupFields(fields: Jira.IssueBean["fields"]) {
    return Object.fromEntries(
      Object.entries(fields).filter((item) => !this.emptyValue(item[1]))
    ) as TemplateIssue["fields"]
  }

  static sortIssueByHierarchy(issues: SortableIssue[]) {
    return issues.sort(
      (left, right) =>
        right.fields.issuetype.hierarchyLevel -
        left.fields.issuetype.hierarchyLevel
    )
  }

  static findRootIssue(issues: SortableIssue[]) {
    if (issues.length < 1) {
      return undefined
    }

    return this.sortIssueByHierarchy(issues)[0]
  }

  static emptyValue(value: unknown) {
    return (
      value === undefined ||
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" && Object.keys(value).length === 0)
    )
  }

  static findParentId(id: Template["id"], tree: { [id: string]: string[], root?: string[] }) {
    const subtree = Object.entries(tree).find(([_parentId, childIds]) =>
      childIds.includes(id)
    )

    if (!subtree) {
      return
    }

    return subtree[0]
  }

  static fromDataCenterTemplate(dcTemplate: LegacyDCTemplate) {
    const { id, name, subtasksDisabled, fields: rootIssueFields } = dcTemplate
    const timestamp = new Date().toISOString()

    const rootIssue = {
      id: generateId(),
      fields: rootIssueFields,
      disabledFields: [],
    }

    const otherIssues = dcTemplate.subtasks.map((fields) => ({
      id: generateId(),
      parentId: rootIssue.id,
      fields,
      disabledFields: [],
    }))

    return {
      id,
      name,
      createChildren: !subtasksDisabled,
      rootIssueId: rootIssue.id,
      issuetype: rootIssue.fields.issuetype,
      issues: [rootIssue, ...otherIssues],
      variables: [],
      insertedAt: timestamp,
      updatedAt: timestamp,
    }
  }
}

export default TemplateUtils
