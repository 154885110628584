import { useState, useEffect } from "react"
import { useMutation } from "@tanstack/react-query"

import { Jira } from "@easy-templates/types"

import Form, { Field, HelperMessage } from 'components/ui/form'
import Loading from "components/Loading"
import WarningBanner from "components/Banner"
import { DialogLayout } from "components/Layout"
import { useAnalytics, UIScope, UIEvent } from "components/Analytics"
import Button, { ButtonGroup } from "components/ui/Button"
import Textfield from "components/ui/Textfield"
import { useAppContext } from "components/AppContextProvider"
import Maintenance from "components/maintenance"


const NewTemplate = (): JSX.Element => {
  const { core, maintenanceModeOn, ...context } = useAppContext()

  const [initialValues, setInitialValues] =
    useState<{
      issueId: string
      subtasksEnabled: boolean
      name: string
    }>()

  const { mutateAsync: createTemplate, isPending: isProcessing } = useMutation({
    mutationFn: async ({
      issueId,
      name,
      subtasksEnabled,
    }: {
      issueId: string
      name: string
      subtasksEnabled: boolean
    }) => {
      const result = await core.createTemplateFromIssue(
        issueId,
        name,
        subtasksEnabled
      )

      if (result.isOk) {
        return result.data
      }

      throw result.error
    }
  })

  const { instrument } = useAnalytics(UIScope.NEW_TEMPLATE_FROM_ISSUE)

  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const issueId = context.issue?.id

    if (issueId) {
      core.getIssue(issueId).then((issue: Jira.IssueBean) => {
        const { summary } = issue.fields

        setInitialValues({
          issueId,
          subtasksEnabled: true,
          name: `${summary} template`,
        })
      })
    } else {
      setError(new Error("Application context is missing!"))
    }
  }, [core, setError])

  const showSuccessNotification = () => {
    core.showFlag({
      id: "template-created",
      description: "Template has been successfully created.",
      type: "success",
    })
  }

  const handleSuccess = () => {
    setError(null)
    showSuccessNotification()
    closeDialog()
  }

  const closeDialog = () => core.closeSaveAsTemplateDialog()

  const handleSubmit = async (values) => {
    setError(null)

    try {
      await createTemplate({ issueId: context.issue.id, subtasksEnabled: true, ...values })
      handleSuccess()
    } catch (error) {
      setError(error)
    }
  }

  const validateName = (name: string) => {
    if (!name.trim()) {
      return "Cannot be blank"
    }

    return
  }

  if (maintenanceModeOn) {
    return <Maintenance onDismiss={closeDialog} />
  }

  if (initialValues === undefined) {
    return <Loading />
  }

  return (
    <Form onSubmit={instrument(
      UIEvent.CREATE_BUTTON_CLICKED,
      handleSubmit
    )}>
      {({ formProps, submitting }) => {
        return (
          <form {...formProps}>
            <DialogLayout
              header={<h3>New Template</h3>}
              actions={
                <ButtonGroup>
                  <Button
                    testId="new-template--cancel-button"
                    onClick={instrument(
                      UIEvent.CANCEL_BUTTON_CLICKED,
                      closeDialog
                    )}
                    isDisabled={submitting}
                    appearance="subtle"
                  >
                    Cancel
                  </Button>
                  <Button
                    testId="new-template--submit-button"
                    appearance="primary"
                    isDisabled={submitting}
                    type="submit"
                    isLoading={submitting}
                  >
                    Create
                  </Button>
                </ButtonGroup>
              }
            >
              {error && <WarningBanner message={error.message} />}
              <Field
                testId="new-template--name-field"
                label="Name"
                isRequired={true}
                isDisabled={submitting}
                name="name"
                defaultValue={initialValues.name}
                validate={validateName}
              >

                {({ fieldProps, valid, error }) => (
                  <>
                    <Textfield
                      autoFocus
                      {...fieldProps}
                    />
                    {!valid && error && <HelperMessage>{error}</HelperMessage>}
                  </>
                )}
              </Field>
            </DialogLayout>
          </form>
        )
      }}
    </Form>
  )
}

export default NewTemplate
