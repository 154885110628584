import { useVariableOptions } from "components/Variables"
import Button from 'components/ui/Button'

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from 'components/ui/ModalDialog'

import { ButtonItem } from "components/ui/Menu"

export default function AddVariableDialog({ isOpen, onClose, onAdd, variables }) {
  const { getVariableTypeIcon } = useVariableOptions()

  const handleSelect = (label: string) => {
    onAdd(label)
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small">
          <ModalHeader>
            <ModalTitle>
              Add Variable
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {variables.map(({ id, type, label, description, isRequired }) => (
              <ButtonItem
                key={id}
                iconBefore={getVariableTypeIcon(type)}
                description={description}
                onClick={() => handleSelect(label)}
              >
                {label}{isRequired && "*"}
              </ButtonItem>
            ))}

          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}
