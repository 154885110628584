import useStyles from './useStyles'

export type Props = {
  children: React.ReactNode
  gutterTop?: boolean
  gutterBottom?: boolean
}

export default ({ children, ...props }: Props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}
