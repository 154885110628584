import { createUseStyles } from "react-jss"

export default createUseStyles({
  root: {
    "--ds-border": "none",
    "--ds-space-100": "0px",
    maxHeight: "50vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  inlineMessageWrapper: {
    // opacity: 0.8,
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconWrapper: {
    // opacity: 0.7,
    width: "24px",
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: 16,
      height: 16,
    },
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
  },
})
