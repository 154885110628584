/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableValue
 */
export interface VariableValue {
}

/**
 * Check if a given object implements the VariableValue interface.
 */
export function instanceOfVariableValue(value: object): value is VariableValue {
    return true;
}

export function VariableValueFromJSON(json: any): VariableValue {
    return VariableValueFromJSONTyped(json, false);
}

export function VariableValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableValue {
    return json;
}

export function VariableValueToJSON(value?: VariableValue | null): any {
    return value;
}

