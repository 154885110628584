import React from "react"
import Button from "components/ui/Button"
import { useFormState } from "components/ui/form"

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/ui/ModalDialog"

type FormChildrenProps = {
  ref: React.RefObject<HTMLFormElement>
  onSubmit: (event?: React.FormEvent<HTMLFormElement> | React.SyntheticEvent<HTMLElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void
}

type Props = {
  heading: string
  submitLabel: string
  closeLabel?: string
  onClose: () => void
  formProps: FormChildrenProps
  children: React.ReactNode
  testId?: string
}

// NOTE: Have to have separate component to so that useFormState works correctly
const Actions = ({ submitLabel, closeLabel, onClose }) => {
  const formState = useFormState({ invalid: true, submitting: true, dirty: true })

  return (
    <>
      <Button type="reset" appearance="subtle" onClick={onClose} testId="cancel">
        {closeLabel || "Cancel"}
      </Button>
      <Button
        appearance="primary"
        autoFocus
        type="submit"
        isDisabled={formState?.submitting || !formState?.dirty}
        isLoading={formState?.submitting}
        testId="submit"
      >
        {submitLabel}
      </Button>
    </>)
}

const Layout = ({
  heading,
  closeLabel,
  submitLabel,
  children,
  onClose,
  formProps,
  testId
}: Props): JSX.Element => {
  return (
    <Modal onClose={onClose} testId="variable-form-dialog" shouldScrollInViewport={true}>
      <form {...formProps} data-testid={testId}>
        <ModalHeader>
          <ModalTitle>{heading}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Actions submitLabel={submitLabel} closeLabel={closeLabel} onClose={onClose} />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default Layout
