/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VariableConfig } from './VariableConfig';
import {
    VariableConfigFromJSON,
    VariableConfigFromJSONTyped,
    VariableConfigToJSON,
} from './VariableConfig';
import type { VariableValue } from './VariableValue';
import {
    VariableValueFromJSON,
    VariableValueFromJSONTyped,
    VariableValueToJSON,
} from './VariableValue';

/**
 * 
 * @export
 * @interface VariableUpdateRequest
 */
export interface VariableUpdateRequest {
    /**
     * Label of the variable, used in markup
     * @type {string}
     * @memberof VariableUpdateRequest
     */
    label?: string;
    /**
     * Description of the variable, used to explain its purpose
     * @type {string}
     * @memberof VariableUpdateRequest
     */
    description?: string;
    /**
     * Whether the variable is required to have a value to create issues from its template
     * @type {boolean}
     * @memberof VariableUpdateRequest
     */
    required?: boolean;
    /**
     * The type of the variable, specifies both the kind of data a variable can store and what widget is used for its display
     * @type {string}
     * @memberof VariableUpdateRequest
     */
    type?: VariableUpdateRequestTypeEnum;
    /**
     * 
     * @type {VariableValue}
     * @memberof VariableUpdateRequest
     */
    defaultValue?: VariableValue;
    /**
     * 
     * @type {VariableConfig}
     * @memberof VariableUpdateRequest
     */
    config?: VariableConfig | null;
}


/**
 * @export
 */
export const VariableUpdateRequestTypeEnum = {
    Text: 'text',
    Longtext: 'longtext',
    Number: 'number',
    Date: 'date',
    Select: 'select',
    Multiselect: 'multiselect'
} as const;
export type VariableUpdateRequestTypeEnum = typeof VariableUpdateRequestTypeEnum[keyof typeof VariableUpdateRequestTypeEnum];


/**
 * Check if a given object implements the VariableUpdateRequest interface.
 */
export function instanceOfVariableUpdateRequest(value: object): value is VariableUpdateRequest {
    return true;
}

export function VariableUpdateRequestFromJSON(json: any): VariableUpdateRequest {
    return VariableUpdateRequestFromJSONTyped(json, false);
}

export function VariableUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'required': json['required'] == null ? undefined : json['required'],
        'type': json['type'] == null ? undefined : json['type'],
        'defaultValue': json['defaultValue'] == null ? undefined : VariableValueFromJSON(json['defaultValue']),
        'config': json['config'] == null ? undefined : VariableConfigFromJSON(json['config']),
    };
}

export function VariableUpdateRequestToJSON(value?: VariableUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'description': value['description'],
        'required': value['required'],
        'type': value['type'],
        'defaultValue': VariableValueToJSON(value['defaultValue']),
        'config': VariableConfigToJSON(value['config']),
    };
}

