import { useState } from 'react'
import sortBy from 'lodash/sortBy'
import { TemplateUtils } from '@easy-templates/lib'
import { Template } from '@easy-templates/types'

import TableTree, { Cell, Row, Rows } from 'components/ui/table-tree'
import Checkbox from 'components/ui/Checkbox'
import IconText from "components/ui/IconText"

import useStyles from './useStyles'

export type Props = {
  tree: Template['tree'],
  issues: Template['issues'],
  disabledIds: string[],
  selectedIds: string[],
  onChange: (selectedIssueIds: IssueID[]) => void
}

type IssueID = string

type Node = {
  id: IssueID
  content: {
    iconUrl: string
    name: string
  }
  rank: string
  hasChildren: boolean
  isDefaultExpanded: boolean
  children: Node[]
}

export default ({ tree, issues, disabledIds, selectedIds, onChange }: Props) => {
  const classes = useStyles()
  const getNode = (id: IssueID, level: number = 0): Node => {
    const issue = issues[id]
    const childIds = tree[id] || []

    return {
      id,
      content: {
        iconUrl: issue.iconUrl,
        name: issue.name
      },
      rank: issue.rank,
      hasChildren: childIds.length > 0,
      isDefaultExpanded: childIds.length < 3 && level === 0,
      children: childIds.map((childId) => getNode(childId, level + 1))
    }
  }

  const defaultState = Object.fromEntries(Object.keys(issues).map<[string, boolean]>(key => [key,
    selectedIds.includes(key)]))

  const [checkedIssueIds, setCheckedIssueIds] = useState<{ [key: IssueID]: boolean }>(defaultState)

  const getAffectedChildIds = (id: IssueID) => {
    const childIds = tree[id] || []

    return [id, ...childIds.flatMap(childId => getAffectedChildIds(childId))]
  }

  const getAffectedParentIds = (id: IssueID) => {
    const parentId = TemplateUtils.findParentId(id, tree)

    if (!parentId || parentId == "root") {
      return [id]
    }

    return [id].concat(getAffectedParentIds(parentId))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...checkedIssueIds }

    let affectedIds = getAffectedChildIds(e.target.id)

    if (e.target.checked) {
      affectedIds = [
        ...affectedIds,
        ...getAffectedParentIds(e.target.id),
      ]
    }

    for (const id of affectedIds) {
      newState[id] = e.target.checked
    }

    setCheckedIssueIds(newState)

    const selectedIssueIds = Object.keys(newState).filter(id => newState[id])
    onChange(selectedIssueIds)
  }

  const items: Node[] = sortBy(tree[tree.root[0]].map((id) => getNode(id, 0)), 'rank')

  return (
    <div className={classes.root} data-testId="structure-selector">
      <TableTree>
        <Rows items={items} render={({ id, children = [], hasChildren, isDefaultExpanded, content }) =>
          <Row itemId={id} items={sortBy(children, 'rank')} hasChildren={hasChildren} isDefaultExpanded={isDefaultExpanded}>
            <Cell>
              <Checkbox
                id={id}
                isDisabled={disabledIds.includes(id)}
                isChecked={checkedIssueIds[id]}
                onChange={handleChange}
                testId={`issue-checkbox-${id}`}
                label={
                  <div className={classes.label}>
                    <IconText iconUrl={content.iconUrl}>
                      {content.name}
                    </IconText>
                  </div>
                }
              />
            </Cell>
          </Row>
        } />
      </TableTree>
    </div>
  )
}
