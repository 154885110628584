import { useQuery } from "@tanstack/react-query"

import { useAppContext } from 'components/AppContextProvider'
import { jobKeys } from 'lib/queryKeys'

interface Return {
  isLoading: boolean
}

type Props = {
  id: string
  onChange: (job: unknown) => void
  onError: (error: Error) => void
}

const useJob = ({ id, onChange, onError }: Props): Return => {
  const { core } = useAppContext()

  return useQuery({
    enabled: !!id,
    queryKey: jobKeys.details(id),
    refetchInterval: 1000,
    queryFn: async () => {
      try {
        const job = await core.getJob(id)

        console.debug("ConnectTemplate.getJob()", { id, job })

        onChange(job)

        return job
      } catch (error) {
        onError(error)
      }
    },
  })
}

export default useJob
