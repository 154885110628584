/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateUseRequestVariablesValue
 */
export interface TemplateUseRequestVariablesValue {
}

/**
 * Check if a given object implements the TemplateUseRequestVariablesValue interface.
 */
export function instanceOfTemplateUseRequestVariablesValue(value: object): value is TemplateUseRequestVariablesValue {
    return true;
}

export function TemplateUseRequestVariablesValueFromJSON(json: any): TemplateUseRequestVariablesValue {
    return TemplateUseRequestVariablesValueFromJSONTyped(json, false);
}

export function TemplateUseRequestVariablesValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateUseRequestVariablesValue {
    return json;
}

export function TemplateUseRequestVariablesValueToJSON(value?: TemplateUseRequestVariablesValue | null): any {
    return value;
}

