import Link from "components/Link"
import { LogOnMount, UIEvent } from "components/Analytics"
import appligerLogo from "images/appliger-logo.png"

import styles from "./styles"

const NotFound = (): JSX.Element => (
  <div style={styles}>
    <h3>Easy Templates for Jira</h3>
    by AppLiger
    <br />
    <img src={appligerLogo} alt="AppLiger" />
    <br />
    <br />
    Need help? Check the&nbsp;
    <Link href="https://go.appliger.com/knowl12a3c">documentation</Link>
    &nbsp;or&nbsp;
    <Link href="mailto: support@appliger.com">contact us</Link>
    <LogOnMount eventType={UIEvent.PAGE_NOT_FOUND} />
  </div>
)

export default NotFound
