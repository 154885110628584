import { Jira } from "@easy-templates/types"

import IdentityExchander from "../identity-exchanger"

export type FieldBuilder = (
  data?: FieldData,
  meta?: Jira.FieldMetadata,
  context?: Context
) => FieldInterface

export type TemplateVariable = {
  fieldName: string
  issueId: string
  type: string
  required: boolean
  label: string
}

export interface FieldInterface {
  name: string
  isSubmitable: () => boolean
  isCascading: () => boolean
  isDefined: () => boolean
  value: () => FieldValue
  prefillValue: () => FieldValue
}

export type FieldValue = unknown

export type Context = {
  parentKey?: string
  variableValues?: { [key: string]: unknown }
  identityExchanger?: IdentityExchander
  projectId?: string
  isSubtask?: boolean
  useADF?: boolean
}

export enum BasicFieldType {
  Array = "array",
  String = "string",
  Number = "number",
}

export enum SystemFieldType {
  Assignee = "assignee",
  Attachment = "attachment",
  Components = "components",
  Date = "date",
  DateTime = "datetime",
  Description = "description",
  Environment = "environment",
  FixVersions = "fixVersions",
  IssueLinks = "issuelinks",
  IssueType = "issuetype",
  Option = "option",
  Parent = "parent",
  Priority = "priority",
  Project = "project",
  Reporter = "reporter",
  SecurityLevel = "security",
  User = "user",
}

export enum CustomFieldType {
  AgileRank = "com.pyxis.greenhopper.jira:gh-lexo-rank",
  AgileSprint = "com.pyxis.greenhopper.jira:gh-sprint",
  Development = "com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf",
  CascadingSelect = "com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect",
  EpicLink = "com.pyxis.greenhopper.jira:gh-epic-link",
  Labels = "com.atlassian.jira.plugin.system.customfieldtypes:labels",
  LabelsNew = "labels",
  MultiCheckboxes = "com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes",
  MultiSelect = "com.atlassian.jira.plugin.system.customfieldtypes:multiselect",
  MultiUserPicker = "com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker",
  OkapyaChecklist = "com.okapya.jira.checklist:checklist",
  ParentLink = "com.atlassian.jpo:jpo-custom-field-parent",
  RadioButtons = "com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons",
  ServiceDeskOrganizations = "com.atlassian.servicedesk:sd-customer-organizations",
  SingleSelect = "com.atlassian.jira.plugin.system.customfieldtypes:select",
  TempoAccountA = "com.tempoplugin.tempo-accounts:accounts.customfield",
  TempoAccountB = "com.atlassian.plugins.atlassian-connect-plugin:io.tempo.jira__account",
  TextArea = "com.atlassian.jira.plugin.system.customfieldtypes:textarea",
  TeamLegacy = "com.atlassian.teams:rm-teams-custom-field-team",
  Team = "com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team",
  UserPicker = "com.atlassian.jira.plugin.system.customfieldtypes:userpicker",
}

export type FieldData = any
