import Help from "components/Help"

import styles from "./styles"

const HelpLinks: React.FC = () => (
  <div style={styles.helpLinks}>
    <Help>Documentation</Help>
  </div>
)

export default HelpLinks
