/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AsyncOperationResult,
  NotFound,
  Template,
  TemplateApplyRequest,
  TemplateApplyingResult,
  TemplateCopyRequest,
  TemplateCreateFromJiraIssueRequest,
  TemplateCreationResult,
  TemplateListItem,
  TemplateUpdateRequest,
  TemplateUseRequest,
  TemplateUsingResult,
} from '../models/index';
import {
    AsyncOperationResultFromJSON,
    AsyncOperationResultToJSON,
    NotFoundFromJSON,
    NotFoundToJSON,
    TemplateFromJSON,
    TemplateToJSON,
    TemplateApplyRequestFromJSON,
    TemplateApplyRequestToJSON,
    TemplateApplyingResultFromJSON,
    TemplateApplyingResultToJSON,
    TemplateCopyRequestFromJSON,
    TemplateCopyRequestToJSON,
    TemplateCreateFromJiraIssueRequestFromJSON,
    TemplateCreateFromJiraIssueRequestToJSON,
    TemplateCreationResultFromJSON,
    TemplateCreationResultToJSON,
    TemplateListItemFromJSON,
    TemplateListItemToJSON,
    TemplateUpdateRequestFromJSON,
    TemplateUpdateRequestToJSON,
    TemplateUseRequestFromJSON,
    TemplateUseRequestToJSON,
    TemplateUsingResultFromJSON,
    TemplateUsingResultToJSON,
} from '../models/index';

export interface TemplateApplyOperationRequest {
    templateId: string;
    templateApplyRequest?: TemplateApplyRequest;
}

export interface TemplateApplyAsyncRequest {
    templateId: string;
    templateApplyRequest?: TemplateApplyRequest;
}

export interface TemplateCopyOperationRequest {
    templateId: string;
    templateCopyRequest?: TemplateCopyRequest;
}

export interface TemplateCreateFromJiraIssueOperationRequest {
    templateCreateFromJiraIssueRequest?: TemplateCreateFromJiraIssueRequest;
}

export interface TemplateDeleteRequest {
    templateId: string;
}

export interface TemplateGetRequest {
    templateId: string;
}

export interface TemplateUpdateOperationRequest {
    templateId: string;
    templateUpdateRequest?: TemplateUpdateRequest;
}

export interface TemplateUseOperationRequest {
    templateId: string;
    templateUseRequest?: TemplateUseRequest;
}

export interface TemplateUseAsyncRequest {
    templateId: string;
    templateUseRequest?: TemplateUseRequest;
}

/**
 * TemplateApi - interface
 * 
 * @export
 * @interface TemplateApiInterface
 */
export interface TemplateApiInterface {
    /**
     * Updates the issue, creates its\' child issues and issue links
     * @summary Apply the template to a Jira issue
     * @param {string} templateId ID of the template
     * @param {TemplateApplyRequest} [templateApplyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateApplyRaw(requestParameters: TemplateApplyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateApplyingResult>>;

    /**
     * Updates the issue, creates its\' child issues and issue links
     * Apply the template to a Jira issue
     */
    templateApply(templateId: string, templateApplyRequest?: TemplateApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateApplyingResult>;

    /**
     * 
     * @summary Apply the template to a Jira issue asynchronously
     * @param {string} templateId ID of the template
     * @param {TemplateApplyRequest} [templateApplyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateApplyAsyncRaw(requestParameters: TemplateApplyAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncOperationResult>>;

    /**
     * Apply the template to a Jira issue asynchronously
     */
    templateApplyAsync(templateId: string, templateApplyRequest?: TemplateApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncOperationResult>;

    /**
     * 
     * @summary Copy a template
     * @param {string} templateId ID of the template
     * @param {TemplateCopyRequest} [templateCopyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateCopyRaw(requestParameters: TemplateCopyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateCreationResult>>;

    /**
     * Copy a template
     */
    templateCopy(templateId: string, templateCopyRequest?: TemplateCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateCreationResult>;

    /**
     * 
     * @summary Create a template from Jira issue
     * @param {TemplateCreateFromJiraIssueRequest} [templateCreateFromJiraIssueRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateCreateFromJiraIssueRaw(requestParameters: TemplateCreateFromJiraIssueOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateCreationResult>>;

    /**
     * Create a template from Jira issue
     */
    templateCreateFromJiraIssue(templateCreateFromJiraIssueRequest?: TemplateCreateFromJiraIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateCreationResult>;

    /**
     * 
     * @summary Delete a template
     * @param {string} templateId ID of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateDeleteRaw(requestParameters: TemplateDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a template
     */
    templateDelete(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get a template
     * @param {string} templateId ID of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateGetRaw(requestParameters: TemplateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Template>>;

    /**
     * Get a template
     */
    templateGet(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Template>;

    /**
     * 
     * @summary Get templates list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateListItem>>>;

    /**
     * Get templates list
     */
    templateList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateListItem>>;

    /**
     * 
     * @summary Update template attributes
     * @param {string} templateId ID of the template
     * @param {TemplateUpdateRequest} [templateUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateUpdateRaw(requestParameters: TemplateUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Update template attributes
     */
    templateUpdate(templateId: string, templateUpdateRequest?: TemplateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Use the template to create issues in Jira
     * @param {string} templateId ID of the template
     * @param {TemplateUseRequest} [templateUseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateUseRaw(requestParameters: TemplateUseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateUsingResult>>;

    /**
     * Use the template to create issues in Jira
     */
    templateUse(templateId: string, templateUseRequest?: TemplateUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateUsingResult>;

    /**
     * 
     * @summary Use the template to create issues in Jira asynchronously
     * @param {string} templateId ID of the template
     * @param {TemplateUseRequest} [templateUseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateUseAsyncRaw(requestParameters: TemplateUseAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncOperationResult>>;

    /**
     * Use the template to create issues in Jira asynchronously
     */
    templateUseAsync(templateId: string, templateUseRequest?: TemplateUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncOperationResult>;

}

/**
 * 
 */
export class TemplateApi extends runtime.BaseAPI implements TemplateApiInterface {

    /**
     * Updates the issue, creates its\' child issues and issue links
     * Apply the template to a Jira issue
     */
    async templateApplyRaw(requestParameters: TemplateApplyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateApplyingResult>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateApply().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/apply`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateApplyRequestToJSON(requestParameters['templateApplyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateApplyingResultFromJSON(jsonValue));
    }

    /**
     * Updates the issue, creates its\' child issues and issue links
     * Apply the template to a Jira issue
     */
    async templateApply(templateId: string, templateApplyRequest?: TemplateApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateApplyingResult> {
        const response = await this.templateApplyRaw({ templateId: templateId, templateApplyRequest: templateApplyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Apply the template to a Jira issue asynchronously
     */
    async templateApplyAsyncRaw(requestParameters: TemplateApplyAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncOperationResult>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateApplyAsync().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/apply-async`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateApplyRequestToJSON(requestParameters['templateApplyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AsyncOperationResultFromJSON(jsonValue));
    }

    /**
     * Apply the template to a Jira issue asynchronously
     */
    async templateApplyAsync(templateId: string, templateApplyRequest?: TemplateApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncOperationResult> {
        const response = await this.templateApplyAsyncRaw({ templateId: templateId, templateApplyRequest: templateApplyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Copy a template
     */
    async templateCopyRaw(requestParameters: TemplateCopyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateCreationResult>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateCopy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/copy`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateCopyRequestToJSON(requestParameters['templateCopyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateCreationResultFromJSON(jsonValue));
    }

    /**
     * Copy a template
     */
    async templateCopy(templateId: string, templateCopyRequest?: TemplateCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateCreationResult> {
        const response = await this.templateCopyRaw({ templateId: templateId, templateCopyRequest: templateCopyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Create a template from Jira issue
     */
    async templateCreateFromJiraIssueRaw(requestParameters: TemplateCreateFromJiraIssueOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateCreationResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateCreateFromJiraIssueRequestToJSON(requestParameters['templateCreateFromJiraIssueRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateCreationResultFromJSON(jsonValue));
    }

    /**
     * Create a template from Jira issue
     */
    async templateCreateFromJiraIssue(templateCreateFromJiraIssueRequest?: TemplateCreateFromJiraIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateCreationResult> {
        const response = await this.templateCreateFromJiraIssueRaw({ templateCreateFromJiraIssueRequest: templateCreateFromJiraIssueRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Delete a template
     */
    async templateDeleteRaw(requestParameters: TemplateDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a template
     */
    async templateDelete(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.templateDeleteRaw({ templateId: templateId }, initOverrides);
    }

    /**
     * Get a template
     */
    async templateGetRaw(requestParameters: TemplateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Template>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateFromJSON(jsonValue));
    }

    /**
     * Get a template
     */
    async templateGet(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Template> {
        const response = await this.templateGetRaw({ templateId: templateId }, initOverrides);
        return await response.value();
    }

    /**
     * Get templates list
     */
    async templateListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateListItemFromJSON));
    }

    /**
     * Get templates list
     */
    async templateList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateListItem>> {
        const response = await this.templateListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update template attributes
     */
    async templateUpdateRaw(requestParameters: TemplateUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateUpdateRequestToJSON(requestParameters['templateUpdateRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update template attributes
     */
    async templateUpdate(templateId: string, templateUpdateRequest?: TemplateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.templateUpdateRaw({ templateId: templateId, templateUpdateRequest: templateUpdateRequest }, initOverrides);
    }

    /**
     * Use the template to create issues in Jira
     */
    async templateUseRaw(requestParameters: TemplateUseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateUsingResult>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateUse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/use`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateUseRequestToJSON(requestParameters['templateUseRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateUsingResultFromJSON(jsonValue));
    }

    /**
     * Use the template to create issues in Jira
     */
    async templateUse(templateId: string, templateUseRequest?: TemplateUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateUsingResult> {
        const response = await this.templateUseRaw({ templateId: templateId, templateUseRequest: templateUseRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Use the template to create issues in Jira asynchronously
     */
    async templateUseAsyncRaw(requestParameters: TemplateUseAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncOperationResult>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling templateUseAsync().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["authorization"] = await this.configuration.apiKey("authorization"); // api_auth authentication
        }

        const response = await this.request({
            path: `/templates/{template_id}/use-async`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateUseRequestToJSON(requestParameters['templateUseRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AsyncOperationResultFromJSON(jsonValue));
    }

    /**
     * Use the template to create issues in Jira asynchronously
     */
    async templateUseAsync(templateId: string, templateUseRequest?: TemplateUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncOperationResult> {
        const response = await this.templateUseAsyncRaw({ templateId: templateId, templateUseRequest: templateUseRequest }, initOverrides);
        return await response.value();
    }

}
