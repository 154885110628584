import BaseAction from "./base"
import { State } from "../types"

export default class IssuesSelectedAction extends BaseAction {
  issueIds: State['issueIds']

  constructor(issueIds: State['issueIds']) {
    super()
    this.issueIds = issueIds
  }

  apply(state: State): State {
    const variables = state.fullSelectedTemplate.variables.filter(
      variable => variable.issueIds.some(issueId => this.issueIds.includes(issueId))
    )

    return {
      ...state,
      issueIds: this.issueIds,
      variables
    }
  }
}
