import { createUseStyles } from "react-jss"

export default createUseStyles({
  root: {
    "--ds-border": "none",
    "--ds-space-100": "0px",
    maxHeight: "50vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  label: {
    marginLeft: -4
  }
})
