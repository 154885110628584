// Render the extension matched by the extension key
import React from 'react'
import Button from '@atlaskit/button'

import { extensionKey } from './const'
import Icon from './Icon'

const AddonIcon = React.createElement(Icon, { label: "Insert Variable" })

export default ({ onEdit }) => ({
  // 'com.atlassian.confluence.macro.core': function(ext) {
  //   console.debug("ext", ext);
  //   // using any here because most props are going to be injected through the extension handler
  //   // and typescript won't accept that as valid
  //   var macroProps = {
  //     node: ext,
  //   };
  //   switch (ext.extensionKey) {
  //     case 'variable-eh':
  //       console.debug("variable-eh");
  //       return React.createElement(Button, { spacing: "compact", iconBefore: AddonIcon }, ext.parameters.macroParams.name);
  //   }
  //
  //   return null
  // },
  'com.atlassian.extensions.update': {
    render: function (ext) {
      console.debug("update.render", ext)
      switch (ext.extensionKey) {
        case extensionKey:
          return React.createElement(Button, { spacing: "compact", iconBefore: AddonIcon }, ext.parameters.isPending ? '...' : ext.parameters.name)
      }

      return null
    },
    update: async (params) => {
      console.debug("update.update", params)
      return await onEdit(params)
      // return __awaiter(void 0, void 0, void 0, function() {
      //   return __generator(this, function(_a) {
      //     return [2 /*return*/, ({
      //       count: params.count + 1,
      //     })];
      //   });
      // });
    },
  },
  'com.atlassian.extensions.noupdate': {
    render: function (ext) {
      console.debug("noupdate.render", ext)
      return React.createElement("button", null, "This is a test extension")
    },
  },
})
