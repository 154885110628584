import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer'
import { ReactRenderer, ADFEncoder } from '@atlaskit/renderer'
import { createUseStyles } from "react-jss"
import { token } from "components/ui/tokens"

const useStyles = createUseStyles({
  root: {
    cursor: "text",
    borderRadius: 4,
    minHeight: 100,
    maxHeight: 400,
    overflowY: "auto",
    border: "2px solid",
    borderColor: token('color.border', "#DFE1E6"),
    padding: 8,
  }
})

export default ({ jiraMarkup }) => {
  const adfEncoder = new ADFEncoder(schema => new WikiMarkupTransformer(schema))
  const document = adfEncoder.encode(jiraMarkup || "")
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ReactRenderer document={document} />
    </div>
  )
}
