// FIXME: DRY it (see lib/result)
type ErrorDescription = {
  errors: { [key: string]: { message: string, details: string }[] }
}

class Result<T> {
  isOk: boolean = true;
  error: ErrorDescription | Error | undefined
  data: T

  constructor(data: T, isOk?: boolean, error?: Error) {
    this.data = data
    this.isOk = isOk !== undefined ? isOk : true
    this.error = error
  }
}

import { FieldBody, Fields } from "./repo"

export enum IssueLinkStrategy {
  LINK_AND_KEEP = "link-and-keep",
  LINK_AND_CLEAR = "link-and-clear",
}

export namespace Jira {
  export interface AdapterInterface {
    getIssue: (id: string) => Promise<Jira.IssueBean>
    getProjects: (params: { id?: String[] }) => Promise<Jira.Project[]>
    closeNewIssueDialog: () => void
    closeApplyTemplateDialog: () => void
    closeSaveAsTemplateDialog: () => void
    openCreateIssueDialog: (
      context: { [field: string]: FieldBody },
      onClose?: (createdIssues: Jira.IssueBean[]) => void
    ) => void
    openExternalLink: (path: string) => void
    refreshIssuePage: () => void
    request: <T = unknown>(url: string, options?: RequestInit) => Promise<T>
    resize: () => void
    showIssueCreatedMessage: ({ issueKey }: { issueKey: string }) => void
    navigateToIssue: ({ key }: { key: string }) => void
    initializeTheming: () => void
    getIssuePickerSuggestions: (
      query: string
    ) => Promise<Jira.SuggestionsResult>
    normalizeImageUrl: (url: string) => string
    showFlag(options: {
      id: string
      title?: string
      description: string
      type?: "error" | "success" | "warning" | "info"
      actions?: { text: string; onClick: () => void }[]
    }): void
    getPermittedProjects: () => Promise<Jira.Project[]>
    getCreateMeta: (params: { projectIds?: string[] }) => Promise<Jira.IssueCreateMetadata>
  }

  export interface APIInterface {
    agile: {
      getIssue: (idOrKey: string) => Promise<IssueBean>
      rankIssuesBefore(keys: string[], beforeKey: string): Promise<unknown>
      rankIssuesAfter(keys: string[], afterKey: string): Promise<unknown>
    }

    // addIssueAttachment: (idOrKey: string, params: any) => Promise<unknown>;
    addIssueComment: (idOrKey: string, comment: unknown) => Promise<unknown>
    // cloneIssueAttachment: (
    //   idOrKey: string,
    //   attachment: Attachment
    // ) => Promise<unknown>;
    getCreateMeta: ({
      projectIds,
    }: {
      projectIds: string[]
    }) => Promise<IssueCreateMetadata>
    getEditMeta: ({
      projectIds,
    }: {
      projectIds: string[]
    }) => Promise<IssueCreateMetadata>
    bulkCreateIssues: (
      issueUpdates: IssueUpdateDetails[]
    ) => Promise<CreatedIssues>
    getUser: (accountId: string) => Promise<unknown>
    getIssue: (idOrKey: string) => Promise<IssueBean>
    // getIssueAttachmentContent: (idOrKey: string) => Promise<unknown>;
    getIssueComments: (idOrKey: string) => Promise<unknown[]>
    getPermittedProjects: () => Promise<Project[]>
    getProject: (idOrKey: string) => Promise<Project>
    getProjects: (params: {
      typeKey?: string
      id?: string[]
    }) => Promise<Project[]>
    search: (jql: string) => Promise<Jira.IssueBean[]>
    createIssueLink: (
      inwardIssueKey: string,
      outwardIssueKey: string,
      typeId: string
    ) => Promise<Result<any>>
    getServerInfo: () => Promise<unknown>
    updateIssue: (
      id: string,
      issueUpdates: IssueUpdateDetails
    ) => Promise<unknown>
  }

  export type Attachment = {
    author: {
      accountId: string
    }
    content: string // URL to the content
    created: string
    filename: string
    id: string
    mimeType: string
    self: string
    size: number
    thumbnail: string
  }

  type AvatarUrlsBean = {
    "16x16": string
    "24x24": string
    "32x32": string
    "48x48": string
  }

  export type FieldMetadata = {
    required: boolean
    name: string
    fieldId?: string // Server
    key: string
    schema: {
      system?: string
      custom?: string
      type?: string
      configuration?: {
        readOnly?: boolean
      }
    }
    autoCompleteUrl?: string
    hasDefaultValue: boolean
    operations: string[] // The list of operations that can be performed on the field.
    allowedValues?: any[] // The list of values allowed in the field.
    defaultValue?: any
    configuration?: any
  }

  export type IssueTypeIssueCreateMetadata = {
    self: string
    id: string
    expand?: string
    description: string
    iconUrl: string
    name: string
    subtask: boolean
    avatarId?: number // The ID of the issue type's avatar.
    entityId?: string // Unique ID for next-gen projects.
    hierarchyLevel?: number // Hierarchy level of the issue type. UUID
    scope?: Scope // Details of the next-gen projects the issue type is available in.
    fields: { [name: string]: FieldMetadata } // List of the fields available when creating an issue for the issue type.
  }

  export type IssueCreateMetadata = {
    expand?: string
    projects: ProjectIssueCreateMetadata[]
  }

  export type IssueBean = {
    expand: string
    self: string
    id: string
    key: string
    properties: Object
    renderedFields?: Object
    names?: Object
    schema?: {
      [key: string]: {
        type: string
        custom?: string
        customId?: string
        system?: string
      }
    }
    transitions?: Object
    fields: Fields
    // operations: Operations;
    // editMeta: IssueUpdateMetadata;
    // changelog: PageOfChangelogs;
    // versionedRepresentations: Object;
    // fieldsToInclude: IncludedFields;
  }

  export type SearchResults = {
    expand: string
    startAt: number
    maxResults: number
    total: number
    issues: IssueBean[]
    warningMessages: string[]
    names: Object
    schema: Object
  }

  export type IssueUpdateDetails = {
    localIssueId?: string
    fields: { [name: string]: any }
  }

  type CreatedIssue = {
    id: string
    key: string
    self: string
    postUpdate?: {
      status: number
      errorCollection: {
        errorMessages: string[]
        errors: unknown[]
      }
    }
  }

  export type ElementErrors = {
    errorMessages: string[]
    errors: { [field: string]: string }
    responseText?: string
  }

  export type Error = {
    status: number
    elementErrors: ElementErrors
    failedElementNumber: number
  }

  export type CreatedIssues = {
    issues: CreatedIssue[]
    errors: Error[]
  }

  export type Project = ProjectBase & {
    issueTypes: IssueTypeDetails[]
  }

  type IssueTypeDetailsScope = {
    type: string
    project: Project
  }

  export type IssueTypeDetails = {
    self: string
    id: string
    description: string
    iconUrl: string
    name: string
    subtask: boolean
    avatarId?: number // Not on Server
    entityId?: string // Not on Server
    scope?: IssueTypeDetailsScope // Not on Server
  }

  export type IssueTypeOverride = {
    id: string | number
    hierarchyLevel: number
  }

  type IssueSuggestion = {
    id: number
    img: string
    key: string
    keyHtml: string
    summary: string
    summaryText: string
  }

  type Section = {
    id: string
    label: string
    sub?: string
    issues: IssueSuggestion[]
    msg?: string
  }

  export type SuggestionsResult = {
    sections: Section[]
  }

  export type ProjectIdentifierBean = {
    id: string
    key: string
  }

  type ProjectBase = {
    self?: string
    id: string
    key: string
    name: string
    avatarUrls?: AvatarUrlsBean
  }

  type ProjectIssueCreateMetadata = {
    id: string
    expand?: string
    issuetypes: IssueTypeIssueCreateMetadata[]
  }

  type UpdatedProjectCategory = {
    self: string
    id: string
    key: string
    name: string
    description: string
  }

  type ProjectDetails = ProjectBase & {
    projectTypeKey: "software" | "service_desk" | "business"
    simplified: boolean
    projectCategory: UpdatedProjectCategory
  }

  type Scope = {
    type: "PROJECT" | "TEMPLATE"
    project: ProjectDetails
  }

  export type Comment = unknown

  export type PlatformContext = {
    cloudId?: string
    issue?: {
      id: string
      issuetype?: {
        id: string
      }
      key: string
    }
    project?: {
      id: string
      key: string
    }
    accountId?: string
    license?: unknown
    moduleKey?: string
  }

  /** Error messages from an operation. */
  export type ErrorCollection = {
    /** The list of error messages produced by this operation. For example, "input parameter 'key' must be provided" */
    errorMessages?: string[]
    /**
     * The list of errors by parameter returned by the operation. For example,"projectKey": "Project keys must start with
     * an uppercase letter, followed by one or more uppercase alphanumeric characters."
     */
    errors?: {}
    status?: number
  }
  export enum IssueLinkStrategy {
    LINK_AND_KEEP = "link-and-keep",
    LINK_AND_CLEAR = "link-and-clear",
  }

  export type User = {
    self: string
    accountId: string
    accountType: string
    avatarUrls: AvatarUrlsBean
    displayName: string
    active: boolean
    timeZone: string
    locale: string
    expand: string
  }
}