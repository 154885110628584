/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectOptions
 */
export interface SelectOptions {
    /**
     * 
     * @type {Array<string>}
     * @memberof SelectOptions
     */
    options: Array<string>;
}

/**
 * Check if a given object implements the SelectOptions interface.
 */
export function instanceOfSelectOptions(value: object): value is SelectOptions {
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function SelectOptionsFromJSON(json: any): SelectOptions {
    return SelectOptionsFromJSONTyped(json, false);
}

export function SelectOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'options': json['options'],
    };
}

export function SelectOptionsToJSON(value?: SelectOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'options': value['options'],
    };
}

