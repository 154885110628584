import Container from "./Container"
import Key from "./Key"

type Option = {
  name?: string
  key?: string
  id?: string
} | string

type Props = {
  id?: string
  key?: string
  icon?: React.ReactNode
  iconComponent?: React.ReactNode
  avatarUrls?: {
    "16x16": string
  }
  name?: string
  iconUrl?: string
}

export const getOptionLabel = (opt: Option): string => {
  if (typeof opt === "string") {
    return opt
  }

  return opt.id || ""
}
export const getOptionValue = (opt: Option): unknown => {
  if (typeof opt === "string") {
    return opt
  }

  return String(opt.id || opt.key)
}

const Option = ({ key, avatarUrls, name, iconUrl, icon, iconComponent }: Props): JSX.Element => (
  <Container label={name} iconComponent={iconComponent} icon={icon} iconUrl={iconUrl || (avatarUrls && avatarUrls["16x16"])}>
    {name}
    {key && <Key value={key} />}
  </Container>
)

export default Option
