/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TemplateApplyRequestVariablesValue } from './TemplateApplyRequestVariablesValue';
import {
    TemplateApplyRequestVariablesValueFromJSON,
    TemplateApplyRequestVariablesValueFromJSONTyped,
    TemplateApplyRequestVariablesValueToJSON,
} from './TemplateApplyRequestVariablesValue';

/**
 * 
 * @export
 * @interface TemplateApplyRequest
 */
export interface TemplateApplyRequest {
    /**
     * ID of issue to apply the template to
     * @type {string}
     * @memberof TemplateApplyRequest
     */
    issueId: string;
    /**
     * ID of issue project, used to get issue editing schema
     * @type {string}
     * @memberof TemplateApplyRequest
     */
    projectId: string;
    /**
     * A map of variable names and values
     * @type {{ [key: string]: TemplateApplyRequestVariablesValue; }}
     * @memberof TemplateApplyRequest
     */
    variables?: { [key: string]: TemplateApplyRequestVariablesValue; };
}

/**
 * Check if a given object implements the TemplateApplyRequest interface.
 */
export function instanceOfTemplateApplyRequest(value: object): value is TemplateApplyRequest {
    if (!('issueId' in value) || value['issueId'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    return true;
}

export function TemplateApplyRequestFromJSON(json: any): TemplateApplyRequest {
    return TemplateApplyRequestFromJSONTyped(json, false);
}

export function TemplateApplyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateApplyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'issueId': json['issueId'],
        'projectId': json['projectId'],
        'variables': json['variables'] == null ? undefined : (mapValues(json['variables'], TemplateApplyRequestVariablesValueFromJSON)),
    };
}

export function TemplateApplyRequestToJSON(value?: TemplateApplyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issueId': value['issueId'],
        'projectId': value['projectId'],
        'variables': value['variables'] == null ? undefined : (mapValues(value['variables'], TemplateApplyRequestVariablesValueToJSON)),
    };
}

