import { TemplateVariable, VariableType } from "@easy-templates/types"

import Variable from "./Variable"

export interface Props {
  variables: TemplateVariable[]
  isDisabled?: boolean
  isHidden?: boolean
}

const Variables = ({
  isHidden,
  variables,
  isDisabled,
}: Props): JSX.Element | null => {
  if (variables.length === 0) return null

  return (
    <div data-testid="variables-form">
      {variables.map(({ id, type, label, required, description, default: defaultValue, config }) => (
        <div key={`${id}-${defaultValue}`} style={{ display: isHidden ? "none" : "block" }}>
          <Variable
            type={type as keyof typeof VariableType}
            label={label}
            isRequired={required}
            defaultValue={defaultValue}
            description={description}
            isDisabled={isDisabled}
            config={config}
          />
        </div>
      ))}
    </div>

  )
}

export default Variables
