import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer'
import { JSONTransformer } from '@atlaskit/editor-json-transformer'

import BasicField from "./basic"

const markupToADF = (text: string) => {
  const wikiTransformer = new WikiMarkupTransformer()
  const jsonTransformer = new JSONTransformer()

  return jsonTransformer.encode(wikiTransformer.parse(text))
}

export default class Description extends BasicField {
  value() {
    return this.applyVariables()
  }

  prefillValue() {
    // NOTE: Forge requires the JSON, Connect - the text...
    if (this.context?.useADF) {
      return markupToADF(this.value())
    }

    return this.value()
  }
}
