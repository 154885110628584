import { token } from "components/ui/tokens"
import { N800 } from '@atlaskit/theme/colors'
import { createUseStyles } from "react-jss"

export const useItemStyles = createUseStyles<"root" | "label" | "children", { isRequired?: boolean }>({
  root: {
    color: token('color.text', N800),
    backgroundColor: token('elevation.surface.overlay', '#fff'),
    borderColor: token('color.border'),
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    minWidth: 250,
    width: '100%',
    minHeight: 32,
    padding: `${token('space.100', '8px')}`,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'center',
    marginBottom: `${token('space.200', '16px')}`,
    marginRight: `${token('space.200', '16px')}`,
    cursor: 'pointer',
    '& .more-actions': {
      minWidth: 38,
    },
    '& .more-actions button[aria-expanded="false"]': {
      display: 'none',
    },
    '&:hover': {
      '& .more-actions button': {
        display: 'block',
      },
    }
  },
  children: {
    flexGrow: 1,
  },
  label: {
    "&:after": {
      color: 'red',
      content: ({ isRequired }) => isRequired ? '"*"' : '',
    }
  }
})

export default createUseStyles({
  root: {},
})
