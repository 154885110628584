import { token } from "components/ui/tokens"
import { createUseStyles } from "react-jss"

type Props = {
  isSelected?: boolean
}

export default createUseStyles({
  root: {},
  issueContainer: {
    minHeight: 22,
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 4px 4px 6px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: token("color.background.input.hovered", "#f7f8f9"),
    },
    backgroundColor: ({ isSelected }: Props = {}) =>
      isSelected ? token("color.background.selected", "#e9f2ff") : "inherit",
  },
  issueNameContainer: {
    display: "flex",
    justifyContent: "space-between",
    overflowWrap: "break-word",
    overflow: "hidden",
    cursor: "pointer",
    flexGrow: 1,
  },
  issueIcon: {
    backgroundColor: token("color.border", "#dfdfdf"),
    borderRadius: 2,
    textIndent: "-10000px",
    height: 16,
    width: 16,
    flexGrow: 0,
    marginRight: 4,
    marginTop: 2,
  },
  issueName: {
    color: ({ isSelected }: Props = {}) =>
      isSelected ? token("color.text.selected", "#0c66e4") : "inherit",
    flexGrow: 1,
  },
  nestedTree: {
    paddingLeft: 20,
  },
  actions: {
    paddingLeft: 4,
    flexShrink: 0,
    marginTop: 0,
  },
})
