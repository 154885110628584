import BasicField from './basic'

export default class NumberField extends BasicField {
  value() {
    const value = parseInt(this.body)

    if (isNaN(value)) {
      return
    }

    return value
  }
}
