import { useState, useEffect } from "react"

import EmptyState from "components/ui/EmptyState"
import { LogOnMount, UIEvent } from "components/Analytics"
import Link from "components/Link"
import Button, { ButtonGroup } from "components/ui/Button"
import { NewTemplateFromIssueInline } from "components/NewTemplateFromIssue"
import { useAppContext } from "components/AppContextProvider"

import emptyImage from "images/empty.png"

const PrimaryAction = () => (
  <Link href="https://go.appliger.com/knowl12a3c">Learn more</Link>
)

type Props = {
  isInProject?: boolean
}

const Empty = ({ isInProject }: Props): JSX.Element => {
  const { core } = useAppContext()
  const [isCreateTemplateVisible, setIsCreateTemplateVisible] = useState(false)

  useEffect(() => {
    setTimeout(core.resizeView, 100)
  }, [core, isCreateTemplateVisible])

  const handleShow = () => setIsCreateTemplateVisible(true)
  const handleHide = () => setIsCreateTemplateVisible(false)

  const header = isInProject
    ? "There are no templates in this project"
    : "There are no templates yet"

  const description = isInProject
    ? "To begin, save a Jira Issue what belongs to this project as a template. Alternatively, click the button to create a template from an existing Issue."
    : "To begin, save a Jira Issue as a template. Alternatively, click the button to create a template from an existing Issue."

  return (
    <>
      <EmptyState
        description={description}
        header={header}
        imageUrl={emptyImage}
        primaryAction={<PrimaryAction />}
        testId="empty-state"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: -32,
        }}
      >
        {isCreateTemplateVisible ? (
          <div
            style={{
              minWidth: 500,
            }}
          >
            <NewTemplateFromIssueInline
              onClose={handleHide}
              onCompleted={handleHide}
            />
          </div>
        ) : (
          <ButtonGroup>
            <Button
              onClick={handleShow}
              appearance="primary"
              testId="empty-state__create-template-button"
            >
              Create Template
            </Button>
          </ButtonGroup>
        )}
      </div>
      <LogOnMount eventType={UIEvent.TEMPLATES_EMPTY_STATE} />
    </>
  )
}

export default Empty
