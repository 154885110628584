import Link from "components/Link"
import QuestionCircleIcon from "components/ui/icons/QuestionCircleIcon"
import styles from "./styles"

type Props = {
  url?: string
  children?: string
}

const Help = ({ url, children }: Props): JSX.Element => {
  const href = url || "https://go.appliger.com/manage1e4b4"
  const label = children || "Help"

  return (
    <div style={styles.helpLinks}>
      <Link href={href}>
        <QuestionCircleIcon label={label} primaryColor="#8777D9" />
      </Link>
      &nbsp;
      <Link href={href}>{label}</Link>
    </div>
  )
}

export default Help
