import { Jira } from "@easy-templates/types"

import {
  FieldInterface,
  Context,
  FieldData,
  BasicFieldType,
  SystemFieldType,
  CustomFieldType,
} from "./fields/types"

// Assignee, etc?
import {
  AssigneeField,
  AgileRankField,
  AgileSprintField,
  ArrayField,
  BasicField,
  CascadingSelectField,
  ComponentsField,
  DateField,
  DateTimeField,
  DescriptionField,
  DevelopmentField,
  EpicLinkField,
  FixVersionsField,
  IssueTypeField,
  LabelsField,
  MultiSelectField,
  MultiUserPickerField,
  NullField,
  ParentField,
  PriorityField,
  ProjectField,
  RadioButtonsField,
  ReporterField,
  SecurityLevelField,
  ServiceDeskOrganizationsField,
  SingleSelectField,
  StringField,
  NumberField,
  TeamField,
  TempoAccountField,
  UserPickerField,
} from "./fields"

const lookupTable = {
  // Custom fields
  [CustomFieldType.AgileRank]: AgileRankField,
  [CustomFieldType.AgileSprint]: AgileSprintField,
  [CustomFieldType.CascadingSelect]: CascadingSelectField,
  [CustomFieldType.Development]: DevelopmentField,
  [CustomFieldType.EpicLink]: EpicLinkField,
  [CustomFieldType.LabelsNew]: LabelsField,
  [CustomFieldType.Labels]: LabelsField,
  [CustomFieldType.MultiSelect]: MultiSelectField,
  [CustomFieldType.MultiUserPicker]: MultiUserPickerField,
  [CustomFieldType.OkapyaChecklist]: BasicField,
  [CustomFieldType.ParentLink]: ParentField,
  [CustomFieldType.RadioButtons]: RadioButtonsField,
  [CustomFieldType.ServiceDeskOrganizations]: ServiceDeskOrganizationsField,
  [CustomFieldType.SingleSelect]: SingleSelectField,
  [CustomFieldType.Team]: TeamField,
  [CustomFieldType.TempoAccountA]: TempoAccountField,
  [CustomFieldType.TempoAccountB]: TempoAccountField,
  [CustomFieldType.UserPicker]: UserPickerField,

  // System fields
  [SystemFieldType.Assignee]: AssigneeField,
  [SystemFieldType.Components]: ComponentsField,
  [SystemFieldType.Date]: DateField,
  [SystemFieldType.DateTime]: DateTimeField,
  [SystemFieldType.Description]: DescriptionField,
  [SystemFieldType.Environment]: DescriptionField,
  [SystemFieldType.FixVersions]: FixVersionsField,
  [SystemFieldType.IssueType]: IssueTypeField,
  [SystemFieldType.Parent]: ParentField,
  [SystemFieldType.Priority]: PriorityField,
  [SystemFieldType.Project]: ProjectField,
  [SystemFieldType.Reporter]: ReporterField,
  [SystemFieldType.SecurityLevel]: SecurityLevelField,

  // Basic fields
  [BasicFieldType.Array]: ArrayField,
  [BasicFieldType.String]: StringField,
  [BasicFieldType.Number]: NumberField,
  unknown: BasicField,
}

export default class Field extends BasicField {
  static build(
    data?: FieldData,
    meta?: Jira.FieldMetadata,
    context?: Context
  ): FieldInterface {
    // Edge-cases
    if (data?.body === undefined || !meta) {
      return new NullField()
    }

    const isNoop =
      Array.isArray(meta.operations) && meta.operations.length === 0
    const isReadonly = meta.schema.configuration?.readOnly

    if ((isNoop || isReadonly) && !["issuetype"].includes(meta.schema.system)) {
      return new NullField()
    }

    const classKey =
      meta.schema.custom || meta.schema.system || meta.schema.type
    const classKeyForType = meta.schema.type || "unknown"

    const fieldClass =
      lookupTable[classKey] || lookupTable[classKeyForType] || BasicField

    return new fieldClass(data, context, null, meta)
  }
}
