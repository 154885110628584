import SVG from "@atlaskit/icon/svg"
import type { SVGProps } from "@atlaskit/icon/types"
import { token } from '../tokens'

const HiddenIcon = (props: SVGProps) => {
  const { primaryColor, size, label } = props
  const defaultPrimaryColor = token('color.icon')

  return (
    <SVG primaryColor={primaryColor || defaultPrimaryColor} size={size} label={label}>
      <rect x="5" y="6" width="2" height="2" fill="currentColor" />
      <rect x="10" y="6" width="2" height="2" fill="currentColor" />

      <rect x="5" y="11" width="2" height="2" fill="currentColor" />
      <rect x="10" y="11" width="2" height="2" fill="currentColor" />

      <rect x="5" y="16" width="2" height="2" fill="currentColor" />
      <rect x="10" y="16" width="2" height="2" fill="currentColor" />
    </SVG>
  )
}

export default HiddenIcon
