import { token } from "components/ui/tokens"
import { createUseStyles } from "react-jss"
import { type Props as ComponentProps } from './'

type Props = Pick<ComponentProps, 'gutterTop' | 'gutterBottom'>

export default createUseStyles({
  root: {
    color: token("color.text.subtle", "#43546f"),
    paddingTop: ({ gutterTop }: Props) => gutterTop ? 16 : 'inherit',
    paddingBottom: ({ gutterBottom }: Props) => gutterBottom ? 8 : 'inherit',
  }
})
