import { VariableType } from '@easy-templates/types'
import { Field, ErrorMessage, HelperMessage } from "components/ui/form"
import fieldFactory from './field-factory'

type ComponentProps = {
  isRequired?: boolean
  type?: keyof typeof VariableType
  description?: string
  isDisabled?: boolean
  defaultValue?: unknown
  testId?: string
  config: { options?: string[] }
}

export type Props = ComponentProps & {
  label: string
}

const toFormValue = (value: unknown, type) => {
  if (type == VariableType.select && value) {
    return { value }
  }

  if (type == VariableType.multiselect) {
    if (!Array.isArray(value)) return null

    return value.map((value) => ({ value }))
  }

  return value
}

const Variable = (props: Props) => {
  const { label, defaultValue: givenDefaultValue, testId, isDisabled, type, isRequired, description, config } = props
  const field = fieldFactory(label, type, isRequired, config)
  const Component = field.component

  const defaultValue = toFormValue(givenDefaultValue, type)

  return (
    <Field name={field.name}
      label={label}
      isDisabled={isDisabled}
      defaultValue={defaultValue || field.default}
      testId={testId || field.testId}
      isRequired={isRequired}
      validate={field.validation.validate}
      transform={field.transformer.transform}
    >
      {({ fieldProps, error }) => (
        <>
          {/* @ts-ignore: Doesn't like onBlur prop */}
          <Component {...fieldProps} {...field.props} />
          {!error && description && <HelperMessage>{description}</HelperMessage>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  )
}

export default Variable
