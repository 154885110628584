import { useQuery } from "@tanstack/react-query"
import { Template } from "@easy-templates/types"

import { templateKeys } from "lib/queryKeys"
import { useAppContext } from "components/AppContextProvider"

interface Return {
  isInitialLoading: boolean
  isLoading: boolean
  error?: Error
}

type Props = {
  id?: string
  onCompleted: (template: Template) => void
  onError?: (error: Error) => void
}

const useTemplate = ({ id, onCompleted, onError }: Props): Return => {
  const { core } = useAppContext()

  return useQuery({
    enabled: !!id,
    queryKey: templateKeys.details(id),
    queryFn: async () => {
      try {
        const template = await core.getTemplate(id)

        onCompleted(template)

        return template
      } catch (error) {
        onError && onError(error)
      }
    },
  })
}

export default useTemplate
