import { useRef } from "react"
import { useQuery } from "@tanstack/react-query"

import { Template } from "@easy-templates/types"
import { useAppContext } from "@easy-templates/ui"

import { TemplateScope } from "@easy-templates/types"
import Select from "components/Field/Select"
import Labeled from "components/Field/Labeled"
import Field from "components/Field"
import User from "components/user"
import { ButtonItem } from "components/ui/Menu"

import useStyles from "./useStyles"
import useUpdateTemplate from "./useUpdateTemplate"
import { jiraKeys } from "lib/queryKeys"

type Props = {
  template: Template
}

const TemplateForm = ({
  template,
}: Props): JSX.Element => {
  const classes = useStyles()
  const { core } = useAppContext()
  const { updateTemplate } = useUpdateTemplate()

  const {
    isLoading: isLoadingProjects,
    data: projects,
    error,
  } = useQuery({
    queryFn: () => core.getProjects({}),
    queryKey: jiraKeys.projects.list(),
  })

  console.debug({ isLoadingProjects, projects, error })

  const handleSuccess = () => { }

  const handleError = () => {
    core.showFlag({
      id: "template-update-error",
      description: "Template updating failed",
      type: "error",
    })
  }

  const scopeOptions = [
    {
      name: "Global",
      value: TemplateScope.GLOBAL,
      description: "Use template globally",
    },
    {
      name: "Selected Projects",
      value: TemplateScope.PROJECTS,
      description: "Use template across selected projects",
    },
  ]

  const handleValueChange = (name: string) => (data) => {
    let value: any

    const defaultValues = {
      id: template.id,
    }

    switch (name) {
      case "scope":
        value = data.value
        break
      case "scopeValue":
        value = data.map(({ id }) => id)
        break
      default:
        value = data
    }

    const values = { ...defaultValues, [name]: value }

    updateTemplate(values, {
      onSuccess: handleSuccess,
      onError: handleError,
    })
  }

  const scope = scopeOptions.find(({ value }) => {
    return value === (template.scope || TemplateScope.GLOBAL)
  })

  const scopeValue = projects?.filter(({ id }) =>
    (template.scopeValue || []).includes(id)
  )

  const projectsOfTemplateIssueType = (projects || []).filter((project) => {
    const projectIssueTypeIds = project.issueTypes.map(({ id }) => id)

    return projectIssueTypeIds.includes(template.issuetype.id)
  })

  return (
    <div data-testid="template-form" className={classes.root}>
      <Field
        name="name"
        label="Name"
        schema={{ type: "string" }}
        value={template.name}
        onConfirm={handleValueChange("name")}
        testId="template-name-field"
      />
      <Field
        label="Create child issues and subtasks"
        name="createChildren"
        schema={{ type: "toggle" }}
        onConfirm={handleValueChange("createChildren")}
        value={template.createChildren}
        testId="template-subtasks-enabled-switch"
      />
      <Labeled
        label="Scope"
        name="scope"
        hint="Switch using the template globally or in the selected projects only"
      >
        <Select
          options={scopeOptions}
          components={{
            Option: (props) => {
              const { innerProps, isDisabled, isSelected, data } = props
              if (isDisabled || isSelected) {
                return null
              }

              return (
                <div {...innerProps}>
                  <ButtonItem description={data.description}>
                    {data.name}
                  </ButtonItem>
                </div>
              )
            },
          }}
          label="Scope"
          name="scope"
          schema={{ type: "option" }}
          onChange={handleValueChange("scope")}
          value={scope}
          testId="template-scope-field"
        />
      </Labeled>
      {!isLoadingProjects && template.scope === TemplateScope.PROJECTS && (
        <Labeled
          label="Projects"
          name="scopeValue"
          hint="The template will be used in the selected projects only"
        >
          <Select
            options={projectsOfTemplateIssueType}
            label="Projects"
            name="scopeValue"
            onChange={handleValueChange("scopeValue")}
            isMulti
            schema={{ type: "array" }}
            defaultValue={scopeValue}
            testId="template-scope-value-field"
            placeholder=""
          />
        </Labeled>
      )}
      {template.createdBy && <Labeled label="Created by" name="createdBy">
        <User accountId={template.createdBy} />
      </Labeled>}
    </div>
  )
}

export default TemplateForm
