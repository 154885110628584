import BaseAction from './base'
import { State } from '../types'

export default class LoadingStatusChanged extends BaseAction {
  isLoading: boolean

  constructor(isLoading: boolean) {
    super()
    this.isLoading = isLoading
  }

  apply(state: State): State {
    return {
      ...state,
      isLoading: this.isLoading,
    }
  }
}
