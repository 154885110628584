import { useMutation, useQueryClient } from "@tanstack/react-query"
import { TemplateVariable } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"
import { templateKeys } from 'lib/queryKeys'

type Params = {
  templateId: string
  onSuccess: () => void
}

type Values = {
  [key: string]: unknown
}

type MutationParams = {
  id: string,
  values: Values
}

export default ({ templateId, onSuccess }: Params) => {
  const queryClient = useQueryClient()
  const { core } = useAppContext()

  const { mutateAsync: updateVariable, isPending: isUpdating } = useMutation({
    mutationFn: async ({ id, values }: MutationParams) => {
      const result = await core.updateTemplateVariable(templateId, id, values)

      if (result.isOk) {
        return result.data
      }

      throw result.error
    },
    onMutate: async ({ id, values }) => {
      const variablesQueryKey = templateKeys.variables(templateId)
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: variablesQueryKey })

      // Snapshot the previous value
      const oldVariables = queryClient.getQueryData(variablesQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(variablesQueryKey, (oldVariables: TemplateVariable[]) => {
        return oldVariables.map(variable => {
          if (variable.id === id) {
            return { ...variable, ...values }
          }

          return variable
        })
      })

      // Return a context object with the snapshotted value
      return { oldVariables }
    },

    onError: (error: Error) => {
      console.error(error)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.details(templateId) })
      queryClient.invalidateQueries({ queryKey: templateKeys.variables(templateId) })
      onSuccess()
    },
    onSettled: (_data, _error, { }) => {
      queryClient.invalidateQueries({
        queryKey: templateKeys.variables(templateId),
      })

      queryClient.invalidateQueries({
        queryKey: templateKeys.details(templateId),
      })
    },
  })

  return { updateVariable, isUpdating }
}
